import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import user from "../Assets/Image/user.png";
import {
  Button,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton,
  Paper,
  Box,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";
import { admins, getAdmin } from "../api/admin";
import CustomTable from "../Common/CustomTable";
import { adminForgotSetPassword } from "../api/adminAuth";
import { getLocalStorage } from "../Common/cookie";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import path from "path-browserify"
function Employees(props) {
  const { socket } = props;
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [hideDelete, setHideDelete] = useState(true);
  const [search, setSearch] = useState({
    username: "",
    name: "",
    email: "",
    type: "",
  });
  const [data, setData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    socket.on("admin", (newObject) => {
      setData((data) => {
        const filteredArray = data.filter((obj) => obj.id !== newObject.id);
        return [...filteredArray, newObject];
      });
    });
  }, [socket]);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          if (response.data.type === "Supar Admin") {
            setHideDelete(false);
          }
          getAllAdmins("");
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  const getAllAdmins = async (search) => {
    const response = await admins({
      token: adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
    });
    if (response?.status === 200) {
      let filtered = response.data;
      if (search.username) {
        filtered = filtered.filter((d) => {
          return d.username.toUpperCase().match(`.*${search.username.toUpperCase()}.*`);
        });
      }
      if (search.name) {
        filtered = filtered.filter((d) => {
          return (
            d.f_name.toUpperCase().match(`.*${search.name.toUpperCase()}.*`) ||
            d.l_name.toUpperCase().match(`.*${search.name.toUpperCase()}.*`)
          );
        });
      }
      if (search.email) {
        filtered = filtered.filter((d) => {
          return d.email.toUpperCase().match(`.*${search.email.toUpperCase()}.*`);
        });
      }
      if (search.type) {
        filtered = filtered.filter((d) => {
          return d.type === search.type;
        });
      }
      if (search.deactivate_account) {
        filtered = filtered.filter((d) => {
          return d.deactivate_account === parseInt(search.deactivate_account);
        });
      }
      setData(filtered);
    }
  };

  useEffect(() => {
    getAllAdmins(search);
  }, [search]);

  const viewAdminData = (data) => {
    navigate("/employee?id=" + data.id);
  };

  const editAdminData = (data) => {
    navigate("/employee?id=" + data.id + "&edit=1");
  };

  const resetPassAdminData = (data) => {
    Swal.fire({
      title: "Are you sure want to restore password?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Restore it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const abc = adminForgotSetPassword({
          token:
            adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
          a_data: {
            username: data.username,
            password: "123456",
          },
        })
          .then((response) => {
            if (response?.status === 200) {
              Swal.fire(
                "Good Job!",
                "Employee Password reset successfully. Your New Password is 123456",
                "success"
              );
              response = undefined;
              getAllAdmins("");
            } else {
              Swal.fire(
                "Oops!",
                "Occuring some error while resetting admin password",
                "error"
              );
              response = undefined;
            }
          })
          .catch((err) => {
            Swal.fire("Oops!", err.response.data.message, "error");
          });
      }
    });
  };

  const deleteAllAdminsData = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let upar = [];
        data.map((d) => {
          if (d !== null) {
            // delete data
          }
        });
        await Promise.all(upar).then(() => {
          Swal.fire("Good Job!", "Admins deleted successfully.", "success");
        });
        getAllAdmins("");
      }
    });
  };

  const createRows = (data) => {
    const headCells = [
      {
        id: "id",
        label: "Id",
      },
      {
        id: "profile",
        label: "Profile",
      },
      {
        id: "username",
        label: "Username",
      },
      {
        id: "name",
        label: "Name",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "type",
        label: "Type",
      },
      {
        id: "deactivate_account",
        label: "Status",
      },
      {
        id: "edit_pass",
        label: "",
        type: "button",
        noSort: true,
        icon: LockResetIcon,
        iconStyle: { color: "blue" },
        noDisplay: hideDelete,
        callback: resetPassAdminData,
      },
      {
        id: "edit",
        label: "",
        type: "button",
        noSort: true,
        icon: EditIcon,
        iconStyle: { color: "blue" },
        noDisplay: hideDelete,
        callback: editAdminData,
      },
      {
        id: "delete_all",
        noDisplay: true,
        callback: deleteAllAdminsData,
      },
    ];
    function createData(
      id,
      profile,
      username,
      name,
      email,
      type,
      deactivate_account,
      link
    ) {
      return {
        id,
        profile,
        username,
        name,
        email,
        type,
        deactivate_account,
        link,
      };
    }

    const rows = data.map((value) => {
      return createData(
        value.id,
        value.profile ? (
          <img
            // src={`${path.join(
            //   process.env.REACT_APP_API,
            //   "uploads",
            //   "Admins",
            //   value.profile
            // )}`}
            src={`${process.env.REACT_APP_API}/uploads/Admins/${value.profile}`}
            alt="avatar"
            style={{ width: 50, height: 50, borderRadius: "20%" }}
          />
        ) : (
          <img
            src={user}
            alt="avatar"
            style={{ width: 50, height: 50, borderRadius: "20%" }}
          />
        ),
        value.username,
        value.f_name + " " + value.l_name,
        value.email,
        <center>
          {(value.type === "Admin" && (
            <Chip style={{ backgroundColor: "#ccccff" }} label="Admin" />
          )) ||
            (value.type === "Supar Admin" && (
              <Chip
                style={{ backgroundColor: "#ccffff" }}
                label="Super Admin"
              />
            )) ||
            (value.type === "Collector" && (
              <Chip style={{ backgroundColor: "#eeffcc" }} label="Collector" />
            )) ||
            (value.type === "Accountant" && (
              <Chip style={{ backgroundColor: "#ffe6cc" }} label="Accountant" />
            )) ||
            (value.type === "Employee" && (
              <Chip style={{ backgroundColor: "#f5d6eb" }} label="Employee" />
            ))}{" "}
        </center>,
        <center>
          {(value.deactivate_account === 0 && (
            <Chip style={{ backgroundColor: "#ccffcc" }} label="Active" />
          )) ||
            (value.deactivate_account === 1 && (
              <Chip style={{ backgroundColor: "#ffcccc" }} label="Deactive" />
            ))}{" "}
        </center>,
        `/employee?id=${value.id}`
      );
    });

    const filters = (
      <>
        <TableRow hover tabIndex={-1}>
          <TableCell>
            {clear ? (
              <Tooltip title="Clear Filters" arrow>
                <IconButton onClick={clearFilter}>
                  <CancelIcon className="primary-text" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <TextField
              fullWidth
              variant="outlined"
              name="username"
              value={search.username}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              variant="outlined"
              name="name"
              value={search.name}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              variant="outlined"
              name="email"
              value={search.email}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <Select
              MenuProps={{ autoFocus: false }}
              value={search.type}
              name="type"
              onChange={onChangeData}
            >
              {[
                { id: "", name: "All" },
                { id: "Supar Admin", name: "Super Admin" },
                { id: "Admin", name: "Admin" },
                { id: "Employee", name: "Employee" },
                { id: "Accountant", name: "Accountant" },
                { id: "Collector", name: "Collector" },
              ].map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <Select
              MenuProps={{ autoFocus: false }}
              value={search.deactivate_account}
              name="deactivate_account"
              onChange={onChangeData}
            >
              {[
                { id: "", name: "All" },
                { id: "0", name: "Active" },
                { id: "1", name: "Deactive" },
              ].map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell style={{ width: "34px" }}></TableCell>
          <TableCell style={{ width: "34px" }}></TableCell>
        </TableRow>
      </>
    );

    return { headCells, rows, filters };
  };

  function clearFilter() {
    setSearch({
      username: "",
      name: "",
      email: "",
      type: "",
      deactivate_account: "",
    });
  }

  function onChangeData(e) {
    if (
      e.target.name === "type" ||
      e.target.name === "username" ||
      e.target.name === "deactivate_account"
    ) {
      setSearch((search) => ({
        ...search,
        [e.target.name]: e.target.value,
      }));
    } else {
      setSearch((search) => ({
        ...search,
        [e.target.name]: e.target.value.toUpperCase(),
      }));
    }
  }

  useEffect(() => {
    let flag = 0;
    Object.keys(search).forEach(function (key) {
      if (search[key] !== "") {
        flag = 1;
      }
    });
    flag === 0 ? setClear(false) : setClear(true);
  }, [search]);

  useEffect(() => {
    let f_data = [];
    if (data.length > 0) {
      data.map((d) => {
        f_data.push({
          id: d.id,
          username: d.username,
          f_name: d.f_name,
          m_name: d.m_name,
          l_name: d.l_name,
          email: d.email,
          phone: d.phone,
          type: d.type,
        });
      });
    }
    setDownloadData(f_data);
  }, [data]);

  const handleExportRows = () => {
    if (downloadData.length > 0) {
      const doc = new jsPDF();
      const tableData = downloadData.map((row) => Object.values(row));
      const tableHeaders = Object.keys(downloadData[0]);

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });

      doc.save("employees.pdf");
    } else {
      Swal.fire({
        title: "Caution",
        text: "You can not download 0 records.",
        icon: "warning",
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Grid>
            <CSVLink
              data={downloadData}
              filename={"employees.csv"}
              className="btn secondary-btn mr-1"
              target="_blank"
            >
              <DownloadIcon /> CSV
            </CSVLink>
            <Button
              onClick={handleExportRows}
              className="btn secondary-btn"
              variant="outlined"
            >
              <DownloadIcon /> PDF
            </Button>
          </Grid>
          <Link className="text-decoration-none ml-3" to="/employee?edit=1">
            <Button className="primary-btn" variant="contained">
              {" "}
              Add Employee
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box component={Paper} sx={{ pt: 2 }}>
            {/* {data.length > 0 ? */}
            <CustomTable {...createRows(data)} hideDelete={true} />
            {/* :
                            <Box sx={{ p: 3 }}><h5>You have not added any admin.</h5></Box>
                        } */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Employees;
