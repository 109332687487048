import axios from "axios";
import { jwtDecode } from 'jwt-decode';

//personal-loans
export const personalLoans = (obj) => {
    const decodedToken = jwtDecode(obj.token);
    if(decodedToken.type === "Supar Admin" || decodedToken.type === "Admin"){
        return axios
        .get("/personal-loan", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
    }else if(decodedToken.type === "Accountant"){
        return axios
        .get("/personal-loan/disbursment", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
    }else if(decodedToken.type === "Employee"){
        return axios
        .get("/personal-loan/kyc", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
    }
};

//get loan
export const getPersonalLoan = (obj) => {
    return axios
        .get("/personal-loan/" + obj.loan_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//add loan data
export const addPersonalLoanData = (obj) => {
    return axios
        .post("/personal-loan/apply", obj.data, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {            
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//update loan data
export const updatePersonalLoanData = (obj) => {       
    return axios
        .patch("/personal-loan", obj.data, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//update loan status
export const updatePersonalLoanStatus = (obj) => {
    return axios
        .patch("/personal-loan/status", obj.data, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};
export const rejectPersonalLoan = (obj) => {
    return axios
        .patch("/personal-loan/reject", obj.data, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};
export const sendEmailPersonalLoan = (obj) => {
    return axios
        .post("/personal-loan/send-email", obj.data, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};