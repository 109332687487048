import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import CustomerData from "../Components/customerData";
import VerifiedIcon from "@mui/icons-material/Verified";
import { getAdmin } from "../api/admin";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  validateDate,
  validateMax,
  validateSalary,
  validateSelect,
  validateText,
} from "../Common/validations";
import { customers } from "../api/customer";
import {
  addPersonalLoanData,
  getPersonalLoan,
  updatePersonalLoanData,
  updatePersonalLoanStatus,
} from "../api/personalLoan";
import { personalLoanEMIlist } from "../api/collection";
import { convertUTCToIST } from "../Common/fun";
function PersonalLoan() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const loan_id = new URLSearchParams(search).get("loan_id");
  const edit = new URLSearchParams(search).get("edit");

  const [adminData, setAdminData] = useState({});
  const [customersData, setCustomersData] = useState([]);
  const [selectedCust, setSelectedCust] = useState(null);
  const [data, setData] = useState({});
  const [emiData, setEmiData] = useState([]);
  const [errors, setErrors] = useState({});
  const [editable, setEditable] = useState(false);
  const [cusSatus, setCusStatus] = useState({
    admin_id: null,
    admin_name: "",
  });

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  const findOptionById = (options, id) => {
    return options.find((option) => option.id == id);
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          if (id && loan_id) {
            await getPLdata(loan_id, response.data);
            const res = await personalLoanEMIlist({
              loan_id: loan_id,
              token: checkCookie?.accessToken,
            });
            if (res.status === 200) {
              setEmiData(res.data);
            }
          } else {
            const cust = await customers({
              token:
                checkCookie?.accessToken ||
                getLocalStorage("rv-admin").accessToken,
            });
            if (cust?.status === 200) {
              setCustomersData(cust.data);
            }
          }
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  const getPLdata = async (id, admin) => {
    const res = await getPersonalLoan({
      loan_id: id,
      token: admin.accessToken,
    });
    if (res?.status === 200) {
      if (!res.data.offered_amount) {
        res.data.offered_tenure = getNext7DayDate();
      }
      setData(res.data);
      const addItem = res.data.status?.find(
        (item) => item.name === "Disbursed"
      );
      setCusStatus(addItem);
      const req_admin_id = res.data.status.find(
        (item) => item.name === "Requested"
      );
      if (!req_admin_id.admin_id) {
        await updatePersonalLoanStatus({
          data: {
            loan_id: id,
            status_name: "Requested",
            admin_id: admin.id,
            admin_name: `${admin.f_name} ${admin.l_name}`,
            approved: 1,
          },
          token: admin.accessToken,
        });
      }
      // const counter_added = res.data.status.find(
      //   (item) => item.name === "Counter Offer"
      // );
      // if (res.data.status_name === "Counter Offer" || counter_added.added) {
      const statusNames = [
        "Counter Offer",
        "Selfie Upload",
        "e-KYC",
        "Bank Details Verification",
        "Upload Bank Statement",
        "e-Sign",
        "Disbursement",
        "Disbursed",
      ];
      if (statusNames.includes(res.data.status_name)) {
        setEditable(true);
      }
    }
  };

  useEffect(() => {
    const offered_interest = data.offered_interest;
    const offered_tenure = data.offered_tenure;
    const offered_amount = data.offered_amount;
    if (data.offered_amount > 5000) {
      if (offered_amount && offered_interest && offered_tenure) {
        const monthlyInterestRate = offered_interest
          ? offered_interest / 12 / 100
          : 0;
        const totalPayments = offered_tenure;

        if (monthlyInterestRate === 0) {
          const EMI = offered_amount / totalPayments;
          setData((data) => ({
            ...data,
            total_payable_amount: Number(offered_amount).toFixed(0),
            emi_amount: Number(EMI).toFixed(0),
          }));
        } else {
          const EMI =
            (offered_amount *
              monthlyInterestRate *
              Math.pow(1 + monthlyInterestRate, totalPayments)) /
            (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);
          // setEmi(EMI);

          // let scheduleHTML = '<table>';
          // scheduleHTML += '<tr><th>Month</th><th>Payment</th><th>Principal</th><th>Interest</th><th>Balance</th></tr>';

          let balance = offered_amount;
          let totalInterest = 0;
          for (let month = 1; month <= totalPayments; month++) {
            const interest = balance * monthlyInterestRate;
            totalInterest += interest;
            const principal = EMI - interest;
            balance -= principal;
            //     scheduleHTML += `<tr><td>${month}</td><td>${EMI.toFixed(0)}</td><td>${principal.toFixed(0)}</td><td>${interest.toFixed(0)}</td><td>${balance.toFixed(0)}</td></tr>`;
          }
          // scheduleHTML += '</table>';
          // setSchedule(scheduleHTML)
          // setTotalInterestAmount(totalInterest)
          const t =
            Number(totalInterest) +
            (offered_amount ? Number(offered_amount) : 0);
          setData((data) => ({
            ...data,
            total_payable_amount: t.toFixed(0),
            emi_amount: EMI.toFixed(0),
          }));
        }
      } else {
        setData((data) => ({
          ...data,
          total_payable_amount: 0,
        }));
      }
    } else {
      setData((data) => ({
        ...data,
        total_payable_amount:
          Number(data.offered_amount ? data.offered_amount : 0) +
          Number(data.offered_interest ? data.offered_interest : 0),
      }));
    }
  }, [data.offered_amount, data.offered_tenure, data.offered_interest]);

  function validateOfferedAmount(str) {
    setErrors((errors) => ({
      ...errors,
      offered_amount: validateSalary(str).error,
    }));
  }
  function validateCustomer(str) {
    setErrors((errors) => ({
      ...errors,
      customer_id: validateSelect(str).error,
    }));
  }
  function validateOfferedTenure(str) {
    setErrors((errors) => ({
      ...errors,
      offered_tenure:
        data.offered_amount > 5000
          ? validateSalary(str).error
          : validateDate(str).error,
    }));
  }
  function validateOfferedInterest(str) {
    setErrors((errors) => ({
      ...errors,
      offered_interest: validateSalary(str).error,
    }));
  }
  function validateTotalPayable(offered_amount, total_payable_amount) {
    setErrors((errors) => ({
      ...errors,
      total_payable_amount: validateMax(offered_amount, total_payable_amount)
        .error,
    }));
  }
  function validateProcessingFee(str) {
    setErrors((errors) => ({
      ...errors,
      processing_fee: validateSalary(str).error,
    }));
  }
  function validateReqAmount(str) {
    setErrors((errors) => ({
      ...errors,
      req_amount: validateSalary(str).error,
    }));
  }

  function validateUpdateForm() {
    validateOfferedAmount(data.offered_amount);
    validateOfferedTenure(data.offered_tenure);
    validateOfferedInterest(data.offered_interest);
    validateTotalPayable(data.offered_amount, data.total_payable_amount);
    validateProcessingFee(data.processing_fee);
    if (!id) {
      validateCustomer();
    }

    let valid =
      validateSalary(data.offered_amount).valid &&
      (data.offered_amount > 5000
        ? validateSalary(data.offered_tenure).valid
        : validateDate(data.offered_tenure).valid) &&
      validateSalary(data.offered_interest).valid &&
      validateMax(data.offered_amount, data.total_payable_amount).valid &&
      validateSalary(data.processing_fee).valid;
    return valid;
  }

  function validateAddForm() {
    validateCustomer();
    validateReqAmount();

    let valid =
      validateSelect(selectedCust).valid &&
      validateSalary(data.req_amount).valid;
    return valid;
  }

  const updateData = async () => {
    if (validateUpdateForm()) {
      const response = await updatePersonalLoanData({
        data: {
          id: id,
          purpose: data.purpose,
          offered_amount: data.offered_amount,
          offered_interest: data.offered_interest ? data.offered_interest : 0,
          offered_tenure: data.offered_tenure
            ? data.offered_tenure
            : getNext7DayDate(),
          processing_fee: data.processing_fee ? data.processing_fee : 0,
          total_payable_amount: data.total_payable_amount,
          emi: data.offered_amount > 5000 ? 1 : 0,
          emi_amount: data.emi_amount ? data.emi_amount : 0,
          loan_verified: 1,
          admin_id: adminData.id,
        },
        token:
          adminData.accessToken || getLocalStorage("rv-admin")?.accessToken,
      });

      if (response?.status === 200) {
        if (id) {
          await updatePersonalLoanStatus({
            data: {
              loan_id: id,
              status_name: "Counter Offer",
              admin_id: adminData.id,
              admin_name: `${adminData.f_name} ${adminData.l_name}`,
              approved: 1,
            },
            token: adminData.accessToken,
          });
          await getPLdata(loan_id, adminData);
          navigate(`/personal-loan?id=${id}`);
        } else {
          navigate("/personal-loans");
        }
      } else {
        Swal.fire("Oops!", response.message, "error");
      }
    }
  };

  const addData = async (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      const response = await addPersonalLoanData({
        data: {
          customer_id: selectedCust,
          purpose: data.purpose,
          req_amount: data.req_amount,
        },
        token:
          adminData.accessToken || getLocalStorage("rv-admin")?.accessToken,
      });
      if (response?.status === 200) {
        if (id) {
          navigate(`/personal-loan?id=${id}`);
        } else {
          navigate("/personal-loans");
        }
      } else {
        Swal.fire("Oops!", response?.message || "", "error");
      }
    }
  };

  const handleChange = async (e) => {
    await updatePersonalLoanStatus({
      data: {
        loan_id: id,
        status_name: "Disbursement",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        approved: 1,
      },
      token: adminData.accessToken,
    });
    await updatePersonalLoanStatus({
      data: {
        loan_id: id,
        status_name: "Disbursed",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        approved: 1,
      },
      token: adminData.accessToken,
    });
    await getPLdata(loan_id, adminData);
  };

  const getNext7DayDate = () => {
    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 7);
    return futureDate.toISOString();
  };

  return (
    <>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Link className="text-decoration-none" to="/loan-collections">
            <Button className="primary-btn" variant="contained">
              {" "}
              Back
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Box component={Paper} sx={{ overflow: "auto" }}>
            <Box
              sx={{
                width: "100%",
                display: "table",
                tableLayout: "fixed",
                p: 2,
              }}
            >
              <Table>
                {!(!id && edit) && (
                  <>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Name</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.name}</>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>
                        Contact No.
                      </TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>
                          {data?.customer?.phone}{" "}
                          {data?.customer?.phone_verified ? (
                            <VerifiedIcon />
                          ) : null}
                        </>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Email</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.email}</>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {!id && edit && (
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Customer</TableCell>
                    <TableCell style={{ width: "80%" }}>
                      <Autocomplete
                        value={
                          findOptionById(customersData, selectedCust) || null
                        } // Ensure value is not null
                        onChange={(event, newValue) => {
                          setSelectedCust(newValue ? newValue.id : null);
                        }}
                        onBlur={() => validateCustomer(selectedCust)}
                        options={
                          !customersData
                            ? [{ label: "Loading...", id: 0 }]
                            : customersData
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        renderOption={(props, option) => (
                          <li {...props}>{option.name}</li>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {id && (
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Status</TableCell>
                    <TableCell style={{ width: "80%" }}>
                      <b>{data.status_name}</b>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Purpose</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="purpose"
                        value={data.purpose}
                        onChange={onChangeData}
                        multiline
                        // rows={2}
                        // maxRows={4}
                      />
                    ) : (
                      <p>{data.purpose}</p>
                    )}
                    {errors.purpose && edit && (
                      <Typography className="error-text">
                        {errors.purpose}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Requested Amount
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit && !id ? (
                      <TextField
                        name="req_amount"
                        value={data.req_amount}
                        onChange={onChangeData}
                        onBlur={() => validateReqAmount(data.req_amount)}
                      />
                    ) : (
                      <b>{data.req_amount}</b>
                    )}
                    {errors.req_amount && edit && (
                      <Typography className="error-text">
                        {errors.req_amount}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>

                {editable && (
                  <>
                    {id && (
                      <>
                        <TableRow>
                          <TableCell style={{ width: "20%" }}>
                            Offered Amount
                          </TableCell>
                          <TableCell style={{ width: "80%" }}>
                            {edit ? (
                              <TextField
                                name="offered_amount"
                                value={data.offered_amount}
                                onChange={onChangeData}
                                onBlur={() =>
                                  validateOfferedAmount(data.offered_amount)
                                }
                              />
                            ) : (
                              <b>{data.offered_amount}</b>
                            )}
                            {errors.offered_amount && edit && (
                              <Typography className="error-text">
                                {errors.offered_amount}
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ width: "20%" }}>
                            Offered Tenure{" "}
                            {data.offered_amount > 5000 && <>(in months)</>}
                          </TableCell>
                          <TableCell style={{ width: "80%" }}>
                            {data.offered_amount > 5000 ? (
                              edit ? (
                                <TextField
                                  name="offered_tenure"
                                  value={
                                    data.offered_tenure
                                      ? data.offered_tenure
                                      : "12"
                                  }
                                  onChange={onChangeData}
                                  onBlur={() =>
                                    validateOfferedTenure(data.offered_tenure)
                                  }
                                />
                              ) : (
                                <p>{data.offered_tenure} months</p>
                              )
                            ) : edit ? (
                              <input
                                type="date"
                                name="offered_tenure"
                                value={
                                  data.offered_tenure
                                    ? data.offered_tenure.split("T")[0]
                                    : getNext7DayDate().split("T")[0]
                                }
                                onChange={onChangeData}
                                onBlur={() =>
                                  validateOfferedTenure(data.offered_tenure)
                                }
                              />
                            ) : data?.offered_tenure ? (
                              new Date(data.offered_tenure).toLocaleDateString(
                                "en-GB"
                              )
                            ) : null}
                            {errors.offered_tenure && edit && (
                              <Typography className="error-text">
                                {errors.offered_tenure}
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ width: "20%" }}>
                            <p>
                              Offered Interest{" "}
                              {data.offered_amount > 5000 ? "(in %)" : "(in ₹)"}
                            </p>
                          </TableCell>
                          <TableCell style={{ width: "80%" }}>
                            {edit ? (
                              <TextField
                                name="offered_interest"
                                value={data.offered_interest}
                                onChange={onChangeData}
                                onBlur={() =>
                                  validateOfferedInterest(data.offered_interest)
                                }
                              />
                            ) : (
                              <b>{data.offered_interest}</b>
                            )}
                            {errors.offered_interest && edit && (
                              <Typography className="error-text">
                                {errors.offered_interest}
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {data.offered_amount > 5000 && (
                      <>
                        <TableRow>
                          <TableCell style={{ width: "20%" }}>
                            EMI Amount
                          </TableCell>
                          <TableCell style={{ width: "80%" }}>
                            {<b>{data.emi_amount ? data.emi_amount : 0}</b>}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>
                        Processing Fee
                      </TableCell>
                      <TableCell style={{ width: "80%" }}>
                        {edit ? (
                          <TextField
                            name="processing_fee"
                            value={data.processing_fee}
                            onChange={onChangeData}
                            onBlur={() =>
                              validateProcessingFee(data.processing_fee)
                            }
                          />
                        ) : (
                          <b>
                            {data.processing_fee ? data.processing_fee : "0"}
                          </b>
                        )}
                        {errors.processing_fee && edit && (
                          <Typography className="error-text">
                            {errors.processing_fee}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>
                        Total Payable Amount
                      </TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <b>
                          {data.total_payable_amount
                            ? data.total_payable_amount
                            : "0"}
                        </b>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </Table>
              {data.status_name === "Disbursement" && id && (
                <div>
                  <span style={{ marginLeft: "5px", marginTop: "6px" }}>
                    Transfered{" "}
                    <b>{Number(data.offered_amount) - data.processing_fee}</b>{" "}
                    Amount:
                  </span>
                  <Switch
                    style={{ color: "#002d5b" }}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              )}
              {data.status_name === "Disbursed" && id && (
                <>
                  <p
                    style={{
                      marginLeft: "5px",
                      fontSize: "13px",
                      fontStyle: "italic",
                    }}
                  >
                    Amount{" "}
                    <b>{Number(data.offered_amount) - data.processing_fee}</b>{" "}
                    transfered by <b>{cusSatus.admin_name}</b>
                  </p>
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Box component="form" sx={{ ml: 3 }}>
          {id && edit && (
            <>
              <Button
                sx={{ mt: 3 }}
                onClick={updateData}
                variant="contained"
                className="primary-btn me-3"
              >
                {" "}
                Update{" "}
              </Button>
              <Link
                className="text-decoration-none"
                to={`/personal-loan?id=${id}`}
              >
                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  className="primary-btn"
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </Link>
            </>
          )}
          {!id && edit && (
            <Button
              sx={{ mt: 3 }}
              onClick={addData}
              variant="contained"
              className="primary-btn"
            >
              {" "}
              Save
            </Button>
          )}
        </Box>
      </Grid>
      {emiData?.length > 0 && (
        <Grid style={{ margin: "20px 0" }}>
          <Typography>
            <b>EMI List</b>
          </Typography>
          <Box
            component={Paper}
            sx={{
              p: 2,
              // boxShadow: "0px 0px 5px #888888",
              borderRadius: "10px",
              backgroundColor: "white",
              width: "100%",
            }}
          >
            <Table>
              <TableHead>
                <TableCell>Due Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Penalty</TableCell>
                <TableCell>Pay Date</TableCell>
                <TableCell>Transaction Type</TableCell>
              </TableHead>
              {emiData.map((e) => {
                return (
                  <TableRow>
                    <TableCell>
                      {new Date(
                        convertUTCToIST(e.due_date)
                      ).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{e.emi_amount}</TableCell>
                    <TableCell>{e.payment_status}</TableCell>
                    <TableCell>
                      {e.penalty_amount ? e.penalty_amount : 0}
                    </TableCell>
                    <TableCell>
                      {e.pay_date
                        ? new Date(
                            convertUTCToIST(e.pay_date)
                          ).toLocaleDateString()
                        : ""}
                    </TableCell>
                    <TableCell>
                      {e.transaction_type ? e.transaction_type : ""}
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </Box>
        </Grid>
      )}
      {id && (
        <CustomerData
          adminData={adminData}
          id={data?.customer_id}
          edit={null}
          noAccordian
          loanData={data}
          setLoanData={setData}
          getPLdata={(loan_id, adminData) => getPLdata(loan_id, adminData)}
        />
      )}
    </>
  );
}
export default PersonalLoan;
