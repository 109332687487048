import {
  Accordion,
  AccordionSummary,
  Button,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import CustPersonal from "./custPersonal";
import CustAddress from "./custAddress";
import CustBank from "./custBank";
import VerifiedIcon from "@mui/icons-material/Verified";
import CustOccupation from "./custOccupation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { getCustomer } from "../api/customer";
import { convertUTCToIST } from "../Common/fun";
import { getLocalStorage } from "../Common/cookie";

function CustomerData(props) {
  const { adminData, id, edit, noAccordian, loanData, setLoanData, getPLdata } =
    props;
  const [expanded, setExpanded] = useState(noAccordian ? "" : "panel1");
  const [data, setData] = useState({});
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getCustData = async () => {
    const response = await getCustomer({
      customer_id: id,
      token: adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
    });
    if (response?.status === 200) {
      response.data.dob = convertUTCToIST(response.data.dob);
      setData(response.data);
    }
  };
  useEffect(() => {
    const asyncFn = async () => {
      if (id) {
        await getCustData();
      }
    };
    asyncFn();
  }, [id]);
  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ flexShrink: 0 }}>
              Personal Details
              {/* {data.verified === 1 ? <VerifiedIcon /> : null} */}
            </Typography>
          </Grid>
        </AccordionSummary>
        <CustPersonal
          adminData={adminData}
          edit={edit}
          data={data}
          setData={setData}
          getCustData={getCustData}
          loanData={loanData}
          setLoanData={setLoanData}
          getPLdata={getPLdata}
        />
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ flexShrink: 0 }}>
              Address Details{" "}
              {/* {data?.address?.[0]?.verified === 1 ? <VerifiedIcon /> : null} */}
            </Typography>
          </Grid>
        </AccordionSummary>
        <CustAddress
          adminData={adminData}
          edit={edit}
          data={data}
          setData={setData}
          getCustData={getCustData}
          loanData={loanData}
          setLoanData={setLoanData}
          getPLdata={getPLdata}
        />
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ flexShrink: 0 }}>
              Occupation Details{" "}
              {/* {data?.occupation?.[0]?.verified === 1 ? <VerifiedIcon /> : null} */}
            </Typography>
          </Grid>
        </AccordionSummary>
        <CustOccupation
          adminData={adminData}
          edit={edit}
          data={data}
          setData={setData}
          getCustData={getCustData}
          loanData={loanData}
          setLoanData={setLoanData}
          getPLdata={getPLdata}
        />
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ flexShrink: 0 }}>
              Bank Details{" "}
              {/* {data?.bank?.[0]?.verified === 1 ? <VerifiedIcon /> : null} */}
            </Typography>
          </Grid>
        </AccordionSummary>
        <CustBank
          adminData={adminData}
          edit={edit}
          data={data}
          setData={setData}
          getCustData={getCustData}
          loanData={loanData}
          setLoanData={setLoanData}
          getPLdata={getPLdata}
        />
      </Accordion>
      {/* <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography sx={{ flexShrink: 0 }}>Documents</Typography>
                    </Grid>
                </AccordionSummary>
                <CustIdentity adminData={adminData} id={id} edit={edit}  />
            </Accordion> */}
    </>
  );
}
export default CustomerData;
