import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import CustomerData from "../Components/customerData";
import { getLoan, readLoan, updateLoanData } from "../api/loan";
import VerifiedIcon from "@mui/icons-material/Verified";
import { getAdmin } from "../api/admin";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  validateMax,
  validateSalary,
  validateSelect,
  validateText,
} from "../Common/validations";
import { customers } from "../api/customer";
import { getCC } from "../api/cc";
function CC() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
//   const edit = new URLSearchParams(search).get("edit");

  const [adminData, setAdminData] = useState({});
  const [customersData, setCustomersData] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          if (id) {
            const res = await getCC({
                cc_id: id,
              token: response.data.accessToken,
            });
            if (res?.status === 200) {
              setData(res.data);
            }
          } else {
            navigate('/ccs')
          }
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Link className="text-decoration-none" to="/ccs">
            <Button className="primary-btn" variant="contained">
              {" "}
              Back
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Box component={Paper} sx={{ overflow: "auto" }}>
            <Box
              sx={{
                width: "100%",
                display: "table",
                tableLayout: "fixed",
                p: 2,
              }}
            >
              <Table>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Name</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.name}</>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>
                        Contact No.
                      </TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>
                          {data?.customer?.phone}{" "}
                          {data?.customer?.phone_verified ? (
                            <VerifiedIcon />
                          ) : null}
                        </>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Email</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.email}</>
                      </TableCell>
                    </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>CC Type</TableCell>
                  <TableCell style={{ width: "80%" }}>
                  
                      <b>{data.cc_type}</b>   </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Reference Id</TableCell>
                  <TableCell style={{ width: "80%" }}>
                      <b>{data.ref_id}</b>
                  </TableCell>
                </TableRow>
              </Table>
            </Box>
          </Box>
        </Grid>
       
      </Grid>
      {id && (
        <CustomerData
          adminData={adminData}
          id={data?.customer_id}
          edit={null}
        />
      )}
    </>
  );
}
export default CC;
