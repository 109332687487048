import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppBar, Box, CssBaseline, Drawer, IconButton, ListItem, ListItemButton, ListItemIcon, List, Toolbar, Typography, ListItemText, Avatar } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { getLocalStorage, removeLocalStorage } from '../Common/cookie'
import { jwtDecode } from 'jwt-decode';

import logo from '../Assets/Image/logo.png'
import { getAdmin } from '../api/admin'
import { loans } from '../api/loan'
import { contacts } from '../api/contact'
import { CustomMenu, MenuIcons } from './menuComponents'
import axios from "axios";

function Menu1(props) {
    const { socket } = props
    const navigate = useNavigate()
    const location = useLocation()
    const [menuItems, setMenuItems] = useState([])

    const getMenuItems = async (str) => {
        const menuItems = [
            { icon: 'HomeIcon', name: 'Dashboard', path: '/', active: ['/', '/home'] },
            { icon: 'Person2Icon', name: 'Employees', path: '/employees', active: ['/employees', '/employee'] },
            { icon: 'Groups2Icon', name: 'Customers', path: '/customers', active: ['/customers', '/customer'] },
            { icon: 'RequestQuoteIcon', name: 'Personal Loans', path: '/personal-loans', active: ['/personal-loans', '/personal-loan'] },
            { icon: 'CurrencyRupeeIcon', name: 'Loan Collection', path: '/loan-collections', active: ['/loan-collections', '/loan-collection'] },
            { icon: 'RequestQuoteIcon', name: 'Personal Loan from Bank', path: '/bank-personal-loans', active: ['/bank-personal-loans', '/bank-personal-loan'] },
            { icon: 'RequestQuoteIcon', name: 'Other Loans', path: '/loans', active: ['/loans', '/loan'] },
            { icon: 'CurrencyRupeeIcon', name: 'Collection', path: '/collections', active: ['/collections', '/collection'] },
            { icon: 'SpaIcon', name: 'Insurance', path: '/insurances', active: ['/insurances', '/insurance'] },
            { icon: 'CreditCardIcon', name: 'Credit Card', path: '/ccs', active: ['/ccs', '/cc'] },
            { icon: 'CreditScoreIcon', name: 'CIBIL Score', path: '/cibilscores', active: ['/cibilscores', '/cibilscore'] },
            { icon: 'ContactSupportIcon', name: 'Contact Query', path: '/contacts', active: ['/contacts', '/contact'] },
            { icon: 'MarkEmailReadIcon', name: 'Subscribers', path: '/subscribers', active: ['/subscribers', '/subscriber'] },
            { icon: 'SettingsIcon', name: 'Extra', path: '/extra', active: ['/extra'] }
        ];

        // useEffect(() => {
        //     socket.on('contact', async (data1) => {
        //         const contactIndex = menuItems.findIndex(obj => obj.name === 'Contact Query');
        //         if (contactIndex !== -1) {
        //             const loan = await contacts({ token: adminData?.accessToken || getLocalStorage('rv-admin').accessToken })
        //             if (loan?.status === 200) {
        //                 menuItems[contactIndex].count = (loan.data.filter(d => {
        //                     return d.read_time === null;
        //                 })).length;
        //             }
        //         }
        //     });

        //     socket.on('loan', async (data1) => {
        //         const loansIndex = menuItems.findIndex(obj => obj.name === 'Loans');
        //         if (loansIndex !== -1) {
        //             const loan = await loans({ token: adminData?.accessToken || getLocalStorage('rv-admin').accessToken })
        //             if (loan?.status === 200) {
        //                 menuItems[loansIndex].count = (loan.data.filter(d => {
        //                     return d.read_time === null;
        //                 })).length;
        //             }
        //         }
        //     });
        // }, [socket])

        if (str === 'Admin' || str === 'Supar Admin') {
            return menuItems.filter(obj => ['Dashboard', 'Employees', 'Customers', 'Personal Loans', 'Loan Collection', 'Personal Loan from Bank', 'Other Loans', 'Collection', 'Credit Card', 'Insurance', 'Contact Query', 'Subscribers', 'Extra'].includes(obj.name));
        } else if (str === 'Employee') {
            return menuItems.filter(obj => ['Dashboard', 'Customers', 'Personal Loans', 'Loan Collection', 'Personal Loan from Bank', 'Other Loans', 'Credit Card', 'Insurance', 'Contact Query', 'Subscribers'].includes(obj.name));
        } else if (str === 'Accountant') {
            return menuItems.filter(obj => ['Dashboard', 'Customers', 'Personal Loans', 'Loan Collection', 'Personal Loan from Bank', 'Other Loans', 'Credit Card', 'Insurance', 'Contact Query', 'Subscribers'].includes(obj.name));
        } else if (str === 'Collector') {
            return menuItems.filter(obj => ['Dashboard', 'Loan Collection'].includes(obj.name));
        }
    }

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        (err) => {
            if (err.response?.status === 401 || err.response?.status === 403) {
                logout()
            }
            return err;
        }
    );

    const logout = () => {
        removeLocalStorage('rv-admin')
        navigate("/login")
    }

    const [anchorElUser, setAnchorElUser] = useState(null);
    const [adminData, setAdminData] = useState({})

    useEffect(() => {
        const asyncFn = async () => {
            const checkCookie = getLocalStorage('rv-admin')
            if (checkCookie) {
                const response = await getAdmin({
                    admin_id: checkCookie.id,
                    token: checkCookie?.accessToken
                })
                if (response?.status === 200) {
                    setAdminData(response.data)                    
                    setMenuItems(await getMenuItems(response.data.type))
                    checkTokenExpiration(response.data.accessToken)
                } else {
                    navigate("/login")
                }
            } else {
                navigate("/login")
            }
        };
        try {
            asyncFn();
        } catch (e) {
            navigate("/login")
        }
    }, [])

    const checkTokenExpiration = (token) => {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
        const currentTime = new Date().getTime();

        const timeUntilExpiration = expirationTime - currentTime;
        // Set timeout to trigger logout when token expires
        setTimeout(logout, timeUntilExpiration);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const drawerWidth = 240;
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Toolbar className="d-flex justify-content-center">
                <Link to="/"><img src={logo} alt="" width="75" /></Link>
            </Toolbar>
            <List>
                {menuItems.length > 0 &&
                    menuItems.map((text, index) => {
                        return <Link key={index} className="text-decoration-none" to={`${text?.path.toLocaleLowerCase()}`} onClick={handleDrawerToggle}>
                            <ListItem disablePadding className={text.active.includes(location.pathname) && 'active-sidebar'}>
                                <ListItemButton className="primary-text" >
                                    <ListItemIcon>
                                        <MenuIcons icon={text.icon} />
                                    </ListItemIcon>
                                    <ListItemText className="primary-table-header-text" primary={text.name} />
                                    <ListItemText className="primary-table-header-text text-end" primary={text.count ? text.count.toString() : null} />
                                </ListItemButton>
                            </ListItem>
                        </Link >
                    })}
            </List>
        </div >
    );

    return (
        <Box className="d-flex" style={{ height: "100vh", overflowY: "auto" }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className="primary-blue-bg"
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` },
                    color: "#fff"
                }}
            >
                <Toolbar className="toolbar-padding" sx={{
                    '&.MuiToolbar-root': {
                        backgroundColor: '#002d5b !important'
                    }
                }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" noWrap component="div" className="">
                        {props.name}
                    </Typography>

                    <Box className="position-fixed" sx={{ flexGrow: 0, right: "20px" }}>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar className="primary-text" sx={{ backgroundColor: "#fff" }}>{adminData?.adminData?.username ? adminData.adminData.username.charAt(0).toUpperCase() : ""}</Avatar> 
                        </IconButton>
                        <CustomMenu handleCloseUserMenu={handleCloseUserMenu} anchorElUser={anchorElUser} data={adminData} userLogout={logout} />
                    </Box>
                </Toolbar>
            </AppBar>

            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block', md: 'none' },
                        boxShadow: 3,
                        '& .MuiDrawer-paper': {backgroundColor: '#002d5b', boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'block' },
                        boxShadow: 3,
                        '& .MuiDrawer-paper': {backgroundColor: '#002d5b', boxSizing: 'border-box', width: drawerWidth, zIndex: '0' },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>

            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar className="toolbar-padding toolbar-mb" />
                <Box>
                    {props.children}
                </Box>
            </Box>
        </Box>
    )
}

export default Menu1;