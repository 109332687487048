import axios from "axios";

export const personalLoanCollection = (obj) => {
    return axios
        .get("/loan-emi/due-date-collection", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};
export const personalLoanSendMail = (obj) => {
    return axios
        .get("/loan-emi/send-mail/" + obj.loan_emi_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};
export const personalLoanEMIlist = (obj) => {
    return axios
        .get("/loan-emi/" + obj.loan_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};
export const dateWiseCollectionList = (obj) => {
    return axios
        .get("/loan-emi/collection-list", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};