import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid, TableRow, TableCell, TextField, Tooltip, IconButton, Paper, Box, Select, MenuItem } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import VerifiedIcon from '@mui/icons-material/Verified';
import CustomTable from '../Common/CustomTable';
import { contacts } from "../api/contact"
import { getLocalStorage } from '../Common/cookie'
import { getAdmin } from "../api/admin";

function Contacts(props) {
    const { socket } = props;
    const navigate = useNavigate()
    const [adminData, setAdminData] = useState({})
    const [search, setSearch] = useState({
        email: '',
        name: '',
        phone: '',
        message: '',
        reply: ''
    })
    const [data, setData] = useState([])
    const [clear, setClear] = useState(false)

    useEffect(() => {
        socket.on('contact', (newObject) => {
            setData(data => {
                const filteredArray = data.filter(obj => obj.id !== newObject.id);
                return [...filteredArray, newObject];
            });
        });
    }, [socket])

    useEffect(() => {
        const asyncFn = async () => {
            const checkCookie = getLocalStorage('rv-admin')
            if (checkCookie) {
                const response = await getAdmin({
                    admin_id: checkCookie.id,
                    token: checkCookie?.accessToken
                })
                if (response?.status === 200) {
                    setAdminData(response.data)
                    getAllContacts('')
                } else {
                    navigate("/login")
                }
            } else {
                navigate("/login")
            }
        };
        try{
            asyncFn();
        }catch(e){
            navigate("/login")
        }
    }, [])

    const getAllContacts = async (search) => {
        const response = await contacts({ token: adminData?.accessToken || getLocalStorage('rv-admin').accessToken || getLocalStorage('rv-admin').accessToken })
        if (response?.status === 200) {
            let filtered = response.data;
            if (search.email) {
                filtered = filtered.filter(d => {
                    return d.email.toUpperCase().match(`.*${search.email.toUpperCase()}.*`);
                });
            }
            if (search.name) {
                filtered = filtered.filter(d => {
                    return d.name.toUpperCase().match(`.*${search.name.toUpperCase()}.*`);
                });
            }
            if (search.phone) {
                filtered = filtered.filter(d => {
                    return d.phone.match(`.*${search.phone}.*`);
                });
            }
            if (search.message) {
                filtered = filtered.filter(d => {
                    return d.message.toUpperCase().match(`.*${search.message.toUpperCase()}.*`);
                });
            }
            if (search.reply === "REPLIED") {
                filtered = filtered.filter(d => {
                    return d.reply !== null;
                });
            }
            if (search.reply === "PENDING") {
                filtered = filtered.filter(d => {
                    return d.reply === null;
                });
            }
            setData(filtered)
        }
    }

    useEffect(() => {
        getAllContacts(search)
    }, [search]);

    const editContactData = (data) => {
        navigate("/contact?id=" + data.id + "&edit=1");
      };

    const createRows = (data) => {
        const headCells = [
            {
                id: 'id',
                label: 'Id',
            },
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'email',
                label: 'Email',
            },
            {
                id: 'phone',
                label: 'Phone',
            },
            {
                id: 'message',
                label: 'Message',
            },
            {
                id: 'reply',
                label: '',
            },
            {
                id: "edit",
                label: "",
                type: "button",
                noSort: true,
                icon: EditIcon,
                iconStyle: { color: "blue" },
                noDisplay: false,
                callback: editContactData,
              },
            {
                id: 'delete_all',
                noDisplay: true
            },
        ]
        function createData(id, name, email, phone, message, reply, read_time, link) {
            return {
                id, name, email, phone, message, reply, read_time, link
            };
        }

        const rows = data.map((value) => {
            return (createData(
                value.id,
                value.name,
                value.email,
                value.phone,
                value.message.length > 50 ? value.message.substring(0, 50) + "..." : value.message,
                value.reply ? <VerifiedIcon /> : null,
                value.read_time,
                `/contact?id=${value.id}`
            ))
        });

        const filters = <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell>
                    {clear ?
                        <Tooltip title="Clear Filters" arrow>
                            <IconButton onClick={clearFilter}>
                                <CancelIcon className='primary-text' />
                            </IconButton>
                        </Tooltip>
                        :
                        <></>
                    }
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="name"
                        value={search.name}
                        onChange={onChangeData} />
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="email"
                        value={search.email}
                        onChange={onChangeData} />
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="phone"
                        value={search.phone}
                        onChange={onChangeData} />
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="message"
                        value={search.message}
                        onChange={onChangeData} />
                </TableCell>
                <TableCell>
                    <Select
                        MenuProps={{ autoFocus: false }}
                        value={search.reply}
                        name="reply"
                        onChange={onChangeData}
                    >
                        {[{ id: '', name: "All" }, { id: "REPLIED", name: "Replied" }, { id: "PENDING", name: "Pending" }].map((option, i) => (
                            <MenuItem key={i} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell style={{ width: "34px" }}></TableCell>
            </TableRow>
        </>

        return { headCells, rows, filters };
    }

    function clearFilter() {
        setSearch({
            email: '',
            name: '',
            phone: '',
            message: '',
            reply: ''
        })
    }

    function onChangeData(e) {
        setSearch((search) => ({
            ...search,
            [e.target.name]: e.target.value.toUpperCase(),
        }));
    }

    useEffect(() => {
        let flag = 0;
        Object.keys(search).forEach(function (key) {
            if (search[key] !== '') {
                flag = 1;
            }
        });
        flag === 0 ? setClear(false) : setClear(true);
    }, [search])
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className="d-flex justify-content-end">
                    <Link className='text-decoration-none' to='/contact?edit=1' style={{ visibility: "hidden" }}><Button className='primary-btn' variant="contained" > Send Mail</Button></Link>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Box component={Paper} sx={{ pt: 2 }}>
                        {/* {data.length > 0 ? */}
                        <CustomTable {...createRows(data)} hideDelete={true} />
                        {/* :
                            <Box sx={{ p: 3 }}><h5>You have not added any admin.</h5></Box>
                        } */}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Contacts;