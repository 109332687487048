import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from '../Common/cookie'

import { Box, TextField, Button, Typography, Grid, Table, TableRow, TableCell, Paper, Autocomplete } from "@mui/material";
import Swal from 'sweetalert2'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import user from '../Assets/Image/user.png'
import { validateContact, validateEmail, validateName, validatePincode, validateSelect, validateUname } from "../Common/validations";
import { getAdmin, updateAdmin } from '../api/admin'
import { getLocation } from '../api/location'
import { adminReg } from '../api/adminAuth'
import path from "path-browserify";
function Employee() {
    const navigate = useNavigate()
    const search = useLocation().search;

    const edit = new URLSearchParams(search).get("edit");
    const [adminData, setAdminData] = useState({})  //for authentication token
    const [id, setId] = useState(null)
    const [data, setData] = useState({}) //for add, update
    const [type, setType] = useState("") //for emp type
    const [image, setImage] = useState({ preview: "", raw: "" }); //for profile image
    const [errors, setErrors] = useState({})

    const setLocation = async (pincode) => {
        if (pincode && pincode.length === 6) {
            const location = await getLocation({
                pincode: pincode
            })
            if (location[0]?.Status === "Success") {
                const lData = location[0].PostOffice[0]
                setData((data) => ({
                    ...data,
                    city: lData.District.toUpperCase(),
                    state: lData.State.toUpperCase(),
                    country: lData.Country.toUpperCase()
                }));
                setErrors((errors) => ({
                    ...errors,
                    pincode: '',
                }));
            } else {
                setErrors((errors) => ({
                    ...errors,
                    pincode: 'Please enter valid Pin Code',
                }));
            }
        };
    }

    useEffect(() => {
        const asyncFn = async () => {
            const checkCookie = getLocalStorage('rv-admin')
            if (checkCookie) {
                getAdminData(checkCookie.id, checkCookie?.accessToken)
            } else {
                navigate("/login")
            }
        };
        try {
            asyncFn();
        } catch (e) {
            navigate("/login")
        }
    }, [])

    const getAdminData = async (id, accessToken) => {
        const response = await getAdmin({
            admin_id: id,
            token: accessToken
        })
        if (response?.status === 200) {
            setAdminData(response.data)
            setId(response.data.id)
            setData(response.data)
            setType(response.data.type)
            if (response.data.pincode) {
                await setLocation(response.data.pincode)
            }
        } else {
            navigate("/login")
        }
    }

    function onChangeData(e) {
        if (e.target.name === "pincode") {
            setLocation(e.target.value)
        }
        setData((data) => ({
            ...data,
            [e.target.name]: e.target.value.toUpperCase(),
        }));
    }

    const handleEditClick = () => {
        document.getElementById('upload-input').click();
    };

    const handleImageChange = e => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    function validateEmailUname(str) {
        setErrors((errors) => ({
            ...errors,
            email: validateEmail(str).error,
        }));
    }
    function validateFirstName(str) {
        setErrors((errors) => ({
            ...errors,
            f_name: validateName(str).error,
        }));
    }
    function validateMiddleName(str) {
        setErrors((errors) => ({
            ...errors,
            m_name: validateName(str).error,
        }));
    }
    function validateLastName(str) {
        setErrors((errors) => ({
            ...errors,
            l_name: validateName(str).error,
        }));
    }
    function validateUsername(str) {
        setErrors((errors) => ({
            ...errors,
            username: validateUname(str).error,
        }));
    }
    function validateType(str) {
        setErrors((errors) => ({
            ...errors,
            type: validateSelect(str).error,
        }));
    }
    function validatePhone(str) {
        setErrors((errors) => ({
            ...errors,
            phone: validateContact(str).error
        }))
    }
    function validateAdd1(str) {
        setErrors((errors) => ({
            ...errors,
            addressline1: validateUname(str).error,
        }))
    }
    function validateArea(str) {
        setErrors((errors) => ({
            ...errors,
            area: validateUname(str).error,
        }))
    }
    function validatePin(str) {
        setErrors((errors) => ({
            ...errors,
            pincode: validatePincode(str).error,
        }))
    }

    function validateForm() {
        validateUsername(data.username)
        validateFirstName(data.f_name)
        validateMiddleName(data.m_name)
        validateLastName(data.l_name)
        validateEmailUname(data.email)
        validateType(type)
        validatePhone(data.phone)
        validateAdd1(data.addressline1)
        validateArea(data.area)
        validatePin(data.pincode)
        let valid =
            validateUname(data.username).valid &&
            validateName(data.f_name).valid &&
            validateName(data.m_name).valid &&
            validateName(data.l_name).valid &&
            validateEmail(data.email).valid &&
            validateSelect(type).valid &&
            validateUname(data.addressline1).valid &&
            validateUname(data.area).valid &&
            validatePincode(data.pincode).valid &&
            validateContact(data.phone).valid;
        return valid;
    }

    const addData = async (e) => {
        e.preventDefault();
        if (!errors.pincode) {
            if (validateForm()) {
                const formData = new FormData();
                formData.append("type", type);
                formData.append("username", data.username);
                formData.append("email", data.email);
                formData.append("f_name", data.f_name);
                formData.append("m_name", data.m_name);
                formData.append("l_name", data.l_name);
                formData.append("addressline1", data.addressline1);
                formData.append("addressline2", data.addressline2);
                formData.append("area", data.area);
                formData.append("pincode", data.pincode);
                formData.append("city", data.city);
                formData.append("state", data.state);
                formData.append("country", data.country);
                formData.append("phone", data.phone);
                formData.append("photo", image.raw ? image.raw : null);
                const response = await adminReg({
                    token: adminData?.accessToken || getLocalStorage('rv-admin').accessToken,
                    data: formData,
                })
                if (response?.status === 200) {
                    Swal.fire(
                        'Good job!',
                        response.message || "Profile updated successfully.",
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Oops!',
                        response.message || "Something went wrong while updating profile.",
                        'error'
                    )
                }
            }
        }
    }

    const updateData = async (e) => {
        e.preventDefault();
        if (!errors.pincode) {
            if (validateForm()) {
                const formData = new FormData();
                formData.append("type", type);
                formData.append("username", data.username);
                formData.append("email", data.email);
                formData.append("f_name", data.f_name);
                formData.append("m_name", data.m_name);
                formData.append("l_name", data.l_name);
                formData.append("addressline1", data.addressline1);
                formData.append("addressline2", data.addressline2);
                formData.append("area", data.area);
                formData.append("pincode", data.pincode);
                formData.append("city", data.city);
                formData.append("state", data.state);
                formData.append("country", data.country);
                formData.append("phone", data.phone);
                formData.append("photo", image.raw ? image.raw : null);
                const response = await updateAdmin({
                    admin_id: id,
                    token: adminData?.accessToken || getLocalStorage('rv-admin').accessToken,
                    data: formData,
                })
                if (response?.status === 200) {
                    Swal.fire(
                        'Good job!',
                        response.message || "Profile updated successfully.",
                        'success'
                    )
                    if (id) {
                        getAdminData(id, adminData?.accessToken || getLocalStorage('rv-admin').accessToken)
                    }
                    navigate('/profile')
                } else {
                    Swal.fire(
                        'Oops!',
                        response.message || "Something went wrong while updating profile.",
                        'error'
                    )
                }
            }
        }
    }

    return (
        <>
            <Grid container spacing={2} sx={{ pb: 2 }}>
                <Grid item xs={12} className="d-flex justify-content-end">
                    {id && !edit && <Link className='text-decoration-none' to={`/profile?edit=1`}><Button className='primary-btn' variant="contained" > Edit</Button></Link>}
                </Grid>
                <Grid item sm={8} xs={12}>
                    <Box component={Paper} sx={{ overflow: "auto" }}>
                        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", p: 2 }}>
                            <Table>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Username</TableCell>
                                    <TableCell style={{ width: "80%" }}>{data.username}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Email</TableCell>
                                    <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                        name="email"
                                        value={data.email}
                                        onChange={onChangeData}
                                        onBlur={() => validateEmailUname(data.email)}
                                    /> : data.email}
                                        {errors.email && (
                                            <Typography className='error-text'>{errors.email}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>First Name</TableCell>
                                    <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                        name="f_name"
                                        value={data.f_name}
                                        onChange={onChangeData}
                                        onBlur={() => validateFirstName(data.f_name)}
                                    /> : data.f_name}
                                        {errors.f_name && (
                                            <Typography className='error-text'>{errors.f_name}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Middle Name</TableCell>
                                    <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                        name="m_name"
                                        value={data.m_name}
                                        onChange={onChangeData}
                                        onBlur={() => validateMiddleName(data.m_name)}
                                    /> : data.m_name}
                                        {errors.m_name && (
                                            <Typography className='error-text'>{errors.m_name}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Last Name</TableCell>
                                    <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                        name="l_name"
                                        value={data.l_name}
                                        onChange={onChangeData}
                                        onBlur={() => validateLastName(data.l_name)}
                                    /> : data.l_name}
                                        {errors.l_name && (
                                            <Typography className='error-text'>{errors.l_name}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Type</TableCell>
                                    <TableCell style={{ width: "80%" }}>{type.toUpperCase()}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Contact No.</TableCell>
                                    <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                        name="phone"
                                        value={data.phone}
                                        onChange={onChangeData}
                                        onBlur={() => validatePhone(data.phone)}
                                    /> : data.phone}
                                        {errors.phone && (
                                            <Typography className='error-text'>{errors.phone}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <Box
                        component={Paper}
                        className="image-preview"
                        style={{ width: '100%', maxWidth: '300px', height: 'auto', maxHeight: '380px', position: 'relative', overflow: 'hidden', display: 'inline-block' }}
                    >
                        {image.preview ?
                            <img src={image.preview} alt="Preview" style={{ width: '100%', height: '100%' }} />
                            : data.profile ?
                            <img src={`${process.env.REACT_APP_API}/uploads/Admins/${data.profile}`} alt="Preview" style={{ width: '100%', height: '100%' }} />

                                // <img src={`${path.join(
                                //                   process.env.REACT_APP_API,
                                //                   "uploads",
                                //                   "Admins",
                                //                   data.profile
                                //                 )}`} alt="Preview" style={{ width: '100%', height: '100%' }} />
                                :
                                <img src={user} alt="Preview" style={{ width: '100%', height: '100%' }} />
                        }
                        {edit && <div
                            className="edit-icon"
                            style={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer', background: 'rgba(255, 255, 255, 0.8)', padding: '5px' }}
                            onClick={handleEditClick}
                        >
                            <CameraAltIcon className="primary-text" />
                        </div>}
                    </Box>
                    <input
                        type="file"
                        id="upload-input"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box component={Paper} sx={{ overflow: "auto" }}>
                        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", p: 2 }}>
                            <Table>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Addressline 1</TableCell>
                                    <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                        name="addressline1"
                                        value={data.addressline1}
                                        onChange={onChangeData}
                                        onBlur={() => validateAdd1(data.addressline1)}
                                    /> : data.addressline1}
                                        {errors.addressline1 && (
                                            <Typography className='error-text'>{errors.addressline1}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Addressline 2</TableCell>
                                    <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                        name="addressline2"
                                        value={data.addressline2}
                                        onChange={onChangeData}
                                    /> : data.addressline2}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Area</TableCell>
                                    <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                        name="area"
                                        value={data.area}
                                        onChange={onChangeData}
                                        onBlur={() => validateArea(data.area)}
                                    /> : data.area}
                                        {errors.area && (
                                            <Typography className='error-text'>{errors.area}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Pin Code</TableCell>
                                    <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                        name="pincode"
                                        value={data.pincode}
                                        onChange={onChangeData}
                                        onBlur={() => validatePin(data.pincode)}
                                    /> : data.pincode}
                                        {errors.pincode && (
                                            <Typography className='error-text'>{errors.pincode}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>City</TableCell>
                                    <TableCell style={{ width: "80%" }}>{data.city}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>State</TableCell>
                                    <TableCell style={{ width: "80%" }}>{data.state}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Country</TableCell>
                                    <TableCell style={{ width: "80%" }}>{data.country}</TableCell>
                                </TableRow>
                            </Table>
                        </Box>
                    </Box>
                </Grid>
                <Box component="form" sx={{ ml: 3 }}>
                    {id && edit && <>
                        <Button sx={{ mt: 3 }} onClick={updateData} variant="contained" className='primary-btn me-3'> Update </Button>
                        <Link className='text-decoration-none' to={`/profile`}><Button sx={{ mt: 3 }} variant="contained" className='primary-btn'> Cancel </Button></Link>
                    </>}
                    {!id && edit && <Button sx={{ mt: 3 }} onClick={addData} variant="contained" className='primary-btn'> Save</Button>}
                </Box>
            </Grid>
        </>
    )
}

export default Employee;
