import {
  AccordionDetails,
  Autocomplete,
  Button,
  Grid,
  Switch,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { validateText, validatePincode } from "../Common/validations";
import { addressDetails, verifyAddress } from "../api/customerAuth";
import { getLocalStorage } from "../Common/cookie";
import { getLocation } from "../api/location";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  sendEmailPersonalLoan,
  updatePersonalLoanStatus,
} from "../api/personalLoan";
function CustAddress(props) {
  const {
    adminData,
    edit,
    data,
    setData,
    getCustData,
    loanData,
    setLoanData,
    getPLdata,
  } = props;
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [cusSatus, setCusStatus] = useState({
    admin_id: null,
    admin_name: "",
  });
  const [addData, setAddData] = useState({
    addressline1: "",
    addressline2: "",
    area: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });

  function onChangeData(e) {
    if (e.target.name === "pincode") {
      setLocation(e.target.value);
    }
    setAddData((addData) => ({
      ...addData,
      [e.target.name]: e.target.value.toUpperCase(),
    }));
  }

  useEffect(() => {
    if (loanData?.id) {
      const addItem = loanData.status.find(
        (item) => item.name === "Address Verification"
      );
      setCusStatus(addItem);
    }
  }, [loanData]);

  async function handleChange() {
    await updatePersonalLoanStatus({
      data: {
        loan_id: loanData.id,
        status_name: "Address Verification",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        approved: 1,
      },
      token: adminData.accessToken,
    });
    await verifyAddress({
      data: {
        id: addData.id,
        verified: 1,
      },
      token: adminData.accessToken,
    });
    await getCustData();
    await getPLdata(loanData.id, adminData);
  }
  async function sendEmail() {
    await sendEmailPersonalLoan({
      data: {
        loan_id: loanData.id,
        email: data.email,
        status_name: "Address Verification",
        extra_info: "",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        link: `${process.env.REACT_APP_FE}/apply-now/Instant/address?id=${loanData.id}`
      },
      token: adminData.accessToken,
    });
    await verifyAddress({
      data: {
        id: addData.id,
        verified: 0,
      },
      token: adminData.accessToken,
    });
    await getCustData();
    await getPLdata(loanData.id, adminData);
  }

  useEffect(() => {
    const asyncFn = async () => {
      if (data?.address?.[0]) {
        setAddData(data.address[0]);
        if (data.address[0]?.pincode) {
          await setLocation(data.address[0].pincode);
        }
      }
    };
    asyncFn();
  }, [data]);

  const setLocation = async (pincode) => {
    if (pincode && pincode.length === 6) {
      const location = await getLocation({
        pincode: pincode,
      });
      if (location[0]?.Status === "Success") {
        const lData = location[0].PostOffice[0];
        setAddData((addData) => ({
          ...addData,
          city: lData.District.toUpperCase(),
          state: lData.State.toUpperCase(),
          country: lData.Country.toUpperCase(),
        }));
        setErrors((errors) => ({
          ...errors,
          pincode: "",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          pincode: "Please enter valid Pin Code",
        }));
      }
    }
  };

  function validateAddr1(str) {
    setErrors((errors) => ({
      ...errors,
      addressline1: validateText(str).error,
    }));
  }
  function validateArea(str) {
    setErrors((errors) => ({
      ...errors,
      area: validateText(str).error,
    }));
  }
  function validatePin(str) {
    setErrors((errors) => ({
      ...errors,
      pincode: validatePincode(str).error,
    }));
  }

  function validateForm() {
    validateAddr1(addData.addressline1);
    validateArea(addData.area);
    validatePin(addData.pincode);
    let valid =
      validateText(addData.addressline1).valid &&
      validateText(addData.area).valid &&
      validatePincode(addData.pincode).valid;
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (!errors.pincode) {
      if (validateForm()) {
        const response = await addressDetails({
          token:
            adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
          data: {
            customer_id: data.id,
            addressline1: addData.addressline1,
            addressline2: addData.addressline2,
            area: addData.area,
            pincode: addData.pincode,
            city: addData.city,
            state: addData.state,
            country: addData.country,
          },
        });
        if (response?.status === 200) {
          Swal.fire(
            "Good Job!",
            "Customer data updated successfully",
            "success"
          );
          getCustData();
        }
      }
    }
  }
  return (
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Addressline 1</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="addressline1"
                    value={addData.addressline1}
                    onChange={onChangeData}
                    onBlur={() => validateAddr1(addData.addressline1)}
                  />
                ) : (
                  addData.addressline1
                )}
                {errors.addressline1 && edit && (
                  <Typography className="error-text">
                    {errors.addressline1}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Addressline 2</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="addressline2"
                    value={addData.addressline2}
                    onChange={onChangeData}
                  />
                ) : (
                  addData.addressline2
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Area</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="area"
                    value={addData.area}
                    onChange={onChangeData}
                    onBlur={() => validateArea(addData.area)}
                  />
                ) : (
                  addData.area
                )}
                {errors.area && edit && (
                  <Typography className="error-text">{errors.area}</Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Pin Code</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="pincode"
                    value={addData.pincode}
                    onChange={onChangeData}
                    onBlur={() => validatePin(addData.pincode)}
                  />
                ) : (
                  addData.pincode
                )}
                {errors.pincode && edit && (
                  <Typography className="error-text">
                    {errors.pincode}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>City</TableCell>
              <TableCell style={{ width: "80%" }}>{addData.city}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>State</TableCell>
              <TableCell style={{ width: "80%" }}>{addData.state}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Country</TableCell>
              <TableCell style={{ width: "80%" }}>{addData.country}</TableCell>
            </TableRow>
          </Table>
          {edit && (
            <Button
              className="primary-btn"
              variant="contained"
              onClick={saveData}
            >
              {" "}
              Save
            </Button>
          )}
          {loanData?.id && (
            <>
              {cusSatus?.admin_name && cusSatus?.approved === 1 ? (
                <p
                  style={{
                    marginLeft: "5px",
                    fontSize: "13px",
                    fontStyle: "italic",
                  }}
                >
                  Details verified by <b>{cusSatus.admin_name}</b>
                </p>
              ) : (
                <>
                  <p style={{ marginLeft: "5px", marginTop: "6px" }}>
                    Verify details:
                  </p>
                  <Switch
                    style={{ color: "#002d5b" }}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Button
                    sx={{ ml: 4 }}
                    className="primary-btn"
                    variant="contained"
                    onClick={sendEmail}
                  >
                    Send mail
                  </Button>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </AccordionDetails>
  );
}
export default CustAddress;
