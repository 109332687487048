import CustomTable from "../Common/CustomTable";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton,
  Paper,
  Box,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getLocalStorage } from "../Common/cookie";
import EditIcon from "@mui/icons-material/Edit";
import { convertUTCToIST } from "../Common/fun";
import { personalLoans } from "../api/personalLoan";
import { getCustomerPersonalLoans } from "../api/customer";

function PersonalLoanData(props) {
  const { socket, adminData, customerId, setDownloadData, noEdit } = props;
  const navigate = useNavigate();

  const [search, setSearch] = useState({
    id: "",
    amount_min: "",
    amount_max: "",
    status: "",
    startDate: 0,
    endDate: 0,
  });
  const [data, setData] = useState([]);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    socket?.on("bank_personal_loan", (newObject) => {
      setData((data) => {
        const filteredArray = data.filter((obj) => obj.id !== newObject.id);
        return [...filteredArray, newObject];
      });
    });
  }, [socket]);

  useEffect(() => {
    let f_data = [];
    if (setDownloadData) {
      if (data.length > 0) {
        data.map((d) => {
          f_data.push({
            id: d.id,
            customer_id: d?.customer?.id,
            phone: d?.customer?.phone,
            name: d?.customer?.name,
            amount: d.amount,
            status: d.status_name,
            applied_on: new Date(d.created_at)
              .toISOString()
              .replace(/T.*/, "")
              .split("-")
              .reverse()
              .join("-"),
          });
        });
      }
      setDownloadData(f_data);
    }
  }, [data]);

  useEffect(() => {
    if(search){
      getAllLoans(search);
    } else {
      getAllLoans("")
    }
  }, [search]);

  const getAllLoans = async (search) => {
    let response;
    if (customerId) {
      response = await getCustomerPersonalLoans({
        customer_id: customerId,
        token:
          adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
      });
    } else {
      response = await personalLoans({
        token:
          adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
      });
    }
    if (response?.status === 200) {
      let filtered = response.data;
      if (search.id) {
        filtered = filtered.filter((d) => {
          return String(d.id).match(`.*${search.id}.*`);
        });
      }
      if (search.status) {
        filtered = filtered.filter((d) => {
          return d.status_name.match(`.*${search.status}.*`);
        });
      }
      if (search.amount_min) {
        filtered = filtered.filter((d) => {
          let a = d.req_amount ? d.req_amount : d.offered_amount;
          return Number(a) >= Number(search.amount_min);
        });
      }
      if (search.amount_max) {
        filtered = filtered.filter((d) => {
          let a = d.req_amount ? d.req_amount : d.offered_amount;
          return Number(a) <= Number(search.amount_max);
        });
      }
      if (search.startDate) {
        filtered = filtered.filter((d) => {
          return new Date(d.created_at) >= new Date(search.startDate);
        });
      }
      if (search.endDate) {
        filtered = filtered.filter((d) => {
          return new Date(d.created_at) <= new Date(search.endDate);
        });
      }
      setData(filtered);
    }
  };

  const status = [
    {
      id: "",
      name: "All",
    },
    {
      id: "Requested",
      name: "Requested",
    },
    {
      id: "Customer Data Verification",
      name: "Customer Data Verification",
    },
    {
      id: "Address Verification",
      name: "Address Verification",
    },
    {
      id: "Occupation Verification",
      name: "Occupation Verification",
    },
    {
      id: "Counter Offer",
      name: "Counter Offer",
    },
    {
      id: "Selfie Upload",
      name: "Selfie Upload",
    },
    {
      id: "e-KYC",
      name: "e-KYC",
    },
    {
      id: "Bank Details Verification",
      name: "Bank Details Verification",
    },
    {
      id: "Upload Bank Statement",
      name: "Upload Bank Statement",
    },
    {
      id: "e-Sign",
      name: "e-Sign",
    },
    {
      id: "Disbursement",
      name: "Disbursement",
    },
    {
      id: "Disbursed",
      name: "Disbursed",
    },
    {
      id: "Rejected",
      name: "Rejected",
    },
  ];
  
  const viewLoanData = (data) => {
    navigate("/personal-loan?id=" + data.id);
  };

  const editLoanData = (data) => {
    navigate("/personal-loan?id=" + data.id + "&edit=1");
  };

  const createRows = (data) => {
    const headCells = [
      {
        id: "id",
        label: "Id",
      },
      {
        id: "amount",
        label: "Amount",
      },
      {
        id: "status",
        label: "Status",
      },
      {
        id: "created_at",
        label: "Applied On",
      },
      {
        id: "edit",
        label: "",
        type: "button",
        noSort: true,
        icon: EditIcon,
        iconStyle: { color: "blue" },
        noDisplay: noEdit,
        callback: editLoanData,
      },
      {
        id: "delete_all",
        noDisplay: true,
      },
    ];
    function createData(
      id,
      amount,
      status,
      created_at,
      link
    ) {
      return {
        id,
        amount,
        status,
        created_at,
        link,
      };
    }

    const rows = data.map((value) => {
      return createData(
        value.id,
        value.req_amount,
        value.status_name,
        // <center>
        //   {(value.status === "Approved" && (
        //     <Chip style={{ backgroundColor: "#eeffcc" }} label="Approved" />
        //   )) ||
        //     (value.status === "Processing" && (
        //       <Chip style={{ backgroundColor: "#ccccff" }} label="Processing" />
        //     )) ||
        //     (value.status === "Rejected" && (
        //       <Chip style={{ backgroundColor: "#ffe6cc" }} label="Rejected" />
        //     )) ||
        //     (value.status === "Requested" && (
        //       <Chip style={{ backgroundColor: "#ccffff" }} label="Requested" />
        //     ))}
        // </center>,
        new Date(convertUTCToIST(value.created_at)).toLocaleDateString(),
        noEdit ? "" : `/personal-loan?id=${value.id}`
      );
    });

    const filters = (
      <>
        <TableRow hover tabIndex={-1}>
          <TableCell>
            {clear ? (
              <Tooltip title="Clear Filters" arrow>
                <IconButton onClick={clearFilter}>
                  <CancelIcon className="primary-text" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell style={{ maxWidth: "50px" }}>
            <TextField
              variant="outlined"
              name="id"
              value={search.id}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell style={{ width: "160px" }}>
            <TextField
              label="Min"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%", maxWidth: "75px" }}
              variant="outlined"
              name="amount_min"
              value={search.amount_min}
              onChange={onChangeData}
            />
            <TextField
              label="Max"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%", maxWidth: "75px" }}
              variant="outlined"
              name="amount_max"
              value={search.amount_max}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <Select
              MenuProps={{ autoFocus: false }}
              value={search.status}
              name="status"
              onChange={onChangeData}
            >
              {status.map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <input
              style={{ width: "45%" }}
              type="date"
              name="startDate"
              max={new Date().toISOString().split("T")[0]}
              value={search.startDate && search.startDate.split("T")[0]}
              onChange={onChangeData}
            />
            <span style={{ marginLeft: "4%", marginRight: "4%" }}>-</span>
            <input
              style={{ width: "45%" }}
              type="date"
              name="endDate"
              value={search.endDate && search.endDate.split("T")[0]}
              min={search.startDate && search.startDate}
              max={new Date().toISOString().split("T")[0]}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell></TableCell>
          {!noEdit && <TableCell style={{ width: "34px" }}></TableCell>}
        </TableRow>
      </>
    );

    return { headCells, rows, filters };
  };

  function clearFilter() {
    setSearch({
      id: "",
      amount_min: "",
      amount_max: "",
      status: "",
      startDate: 0,
      endDate: 0,
    });
  }

  function onChangeData(e) {
    if (e.target.name === "startDate" && search.endDate !== null) {
      setSearch((search) => ({
        ...search,
        endDate: null,
      }));
    }
    if (e.target.name === "endDate" && search.startDate === null) {
      setSearch((search) => ({
        ...search,
        startDate: e.target.value,
      }));
    }
    setSearch((search) => ({
      ...search,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    let flag = 0;
    Object.keys(search).forEach(function (key) {
      if (search[key] !== "" && search[key] !== 0) {
        flag = 1;
      }
    });
    flag === 0 ? setClear(false) : setClear(true);
  }, [search]);

  return (
    <Box component={Paper} sx={{ pt: 2, pb: 2, ml: 0, width: "100%" }}>
      <CustomTable
        {...createRows(data)}
        hideDelete={true}
        checkHighlight={true}
      />
    </Box>
  );
}
export default PersonalLoanData;
