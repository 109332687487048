import axios from "axios";

//customers
export const customers = (obj) => {
    return axios
        .get("/customer", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//get customer
export const getCustomer = (obj) => {    
    return axios
        .get("/customer/" + obj.customer_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//get customer
export const getCustomerPersonalLoans = (obj) => {    
    return axios
        .get("/customer/personal-loan/" + obj.customer_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};
export const getCustomerBankPersonalLoans = (obj) => {    
    return axios
        .get("/customer/bank-personal-loan/" + obj.customer_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};
export const getCustomerLoans = (obj) => {    
    return axios
        .get("/customer/loan/" + obj.customer_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//delete customer
export const deleteCustomer = (obj) => {
    return axios
        .delete("/customer/" + obj.customer_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};