import axios from "axios";

//login
export const customerLogin = (obj) => {
  return axios
    .post("customer-auth/login", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//otp
export const customerOtp = (obj) => {
  return axios
    .post("/customer-auth/otp", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//personal
export const personalDetails = (obj) => {
  return axios
    .post("customer-auth/register-details", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//address
export const addressDetails = (obj) => {
  return axios
    .post("customer-address", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//professional
export const professionalDetails = (obj) => {
  return axios
    .post("customer-occupation", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//identity
export const identityDetails = (obj) => {
  return axios
    .post("customer-auth/register-identity", obj.data, {
      headers: {
        Authorization: `Bearer ${obj.token}`,
        "content-type": "multipart/form-data"
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//bank
export const bankDetails = (obj) => {
  return axios
    .post("customer-auth/register-bank", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

export const verifyPersonal = (obj) => {
  return axios
    .post("customer/verify", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const verifyBank = (obj) => {
  return axios
    .post("customer/verify-bank", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const verifyAddress = (obj) => {
  return axios
    .post("customer-address/verify", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const verifyOccupation = (obj) => {
  return axios
    .post("customer-occupation/verify", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};