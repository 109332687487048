import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import CustomerData from "../Components/customerData";
import { getLoan, readLoan, updateLoanData } from "../api/loan";
import VerifiedIcon from "@mui/icons-material/Verified";
import { getAdmin } from "../api/admin";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { validateSelect } from "../Common/validations";
import { customers, getCustomer } from "../api/customer";
function Loan() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const edit = new URLSearchParams(search).get("edit");

  const [adminData, setAdminData] = useState({});
  const [customersData, setCustomersData] = useState([]);
  const [selectedCust, setSelectedCust] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value.toUpperCase(),
    }));
  }

  const findOptionById = (options, id) => {
    return options.find((option) => option.id == id);
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          if (id) {
            const res = await getLoan({
              loan_id: id,
              token: response.data.accessToken,
            });
            if (res?.status === 200) {
              setData(res.data);
              setSelectedStatus(res.data.status)
            }
            if (!data.read_time || !data.admin_id) {
              const res1 = await readLoan({
                token: response.data.accessToken,
                data: {
                  id: id,
                  admin_id: response.data.id,
                },
              });
            }
          } else {
            const cust = await customers({
              token:
                checkCookie?.accessToken ||
                getLocalStorage("rv-admin").accessToken,
            });
            if (cust?.status === 200) {
              setCustomersData(cust.data);
            }
          }
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  function validateLoanType(str) {
    setErrors((errors) => ({
      ...errors,
      loan_type: validateSelect(str).error,
    }));
  }
  function validateBankName(str) {
    setErrors((errors) => ({
      ...errors,
      bank_name: validateSelect(str).error,
    }));
  }
  function validateLoanStatus(str) {
    setErrors((errors) => ({
      ...errors,
      status: validateSelect(str).error,
    }));
  }
  function validateCustomer(str) {
    setErrors((errors) => ({
      ...errors,
      customer_id: validateSelect(str).error,
    }));
  }

  function validateAddForm() {
    validateLoanType(data.loan_type);
    validateLoanStatus(selectedStatus);
    validateBankName(data.bank_name);
    if (!id) {
      validateCustomer();
    }

    let valid =
      validateSelect(data.loan_type).valid &&
      validateSelect(selectedStatus).valid &&
      validateSelect(data.bank_name).valid &&
      !id
        ? validateSelect(selectedCust).valid
        : true;
    return valid;
  }

  const addData = async (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      const cust = await getCustomer({
        customer_id: id ? data.customer_id : selectedCust,
        token:
          adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
      });
      const response = await updateLoanData({
        data: {
          ...(id ? {loan_id: id} : {}),
          name: cust.data.name,
          email: cust.data.email,
          phone: cust.data.phone,
          pan_no: cust.data.pan_no,
          pincode: cust.data?.address?.[0]?.pincode,
          city: cust.data?.address?.[0]?.city,
          state: cust.data?.address?.[0]?.state,
          country: cust.data?.address?.[0]?.country,
          occupation_type: cust.data?.occupation?.[0]?.occupation_type,
          income: cust.data?.occupation?.[0]?.income,
          c_name: cust.data?.occupation?.[0]?.c_name,
          status: selectedStatus,
          loan_type: data.loan_type,
          purpose: data.purpose,
          bank_name: data.bank_name,
          amount: data.amount,
          reference_id: data.reference_id,
        },
        token:
          adminData.accessToken || getLocalStorage("rv-admin")?.accessToken,
      });
      if (response?.status === 200) {
        if (id) {
          navigate(`/loan?id=${id}`);
        } else {
          navigate("/loans");
        }
      } else {
        Swal.fire("Oops!", "Something went wrong...", "error");
      }
    }
  };

  const loanTypes = [
    {
      id: "",
      name: "All",
    },
    {
      id: "Business Loan",
      name: "Business Loan",
    },
    {
      id: "Education Loan",
      name: "Education Loan",
    },
    {
      id: "Machinary Loan",
      name: "Machinary Loan",
    },
    {
      id: "Home Loan",
      name: "Home Laon",
    },
    {
      id: "Loan Against Property",
      name: "Loan Against Property",
    },
    {
      id: "Professional Loan",
      name: "Professional Loan",
    },
    {
      id: "Car Loan",
      name: "Car Loan",
    },
    {
      id: "Student Loan",
      name: "Student Loan",
    },
  ];

  return (
    <>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Link className="text-decoration-none" to="/loans">
            <Button className="primary-btn" variant="contained">
              {" "}
              Back
            </Button>
          </Link>
          {id && !edit && (
            <Link className="text-decoration-none" to={`/loan?id=${id}&edit=1`}>
              <Button className="primary-btn" variant="contained">
                {" "}
                Edit
              </Button>
            </Link>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box component={Paper} sx={{ overflow: "auto" }}>
            <Box
              sx={{
                width: "100%",
                display: "table",
                tableLayout: "fixed",
                p: 2,
              }}
            >
              <Table>
                {!(!id && edit) && (
                  <>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Name</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.name}</>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>
                        Contact No.
                      </TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>
                          {data?.customer?.phone}{" "}
                          {data?.customer?.phone_verified ? (
                            <VerifiedIcon />
                          ) : null}
                        </>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Email</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.email}</>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {!id && edit && (
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Customer</TableCell>
                    <TableCell style={{ width: "80%" }}>
                      <Autocomplete
                        value={
                          findOptionById(customersData, selectedCust) || null
                        }
                        onChange={(event, newValue) => {
                          setSelectedCust(newValue ? newValue.id : null);
                        }}
                        onBlur={() => validateCustomer(selectedCust)}
                        options={
                          !customersData
                            ? [{ label: "Loading...", id: 0 }]
                            : customersData
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        renderOption={(props, option) => (
                          <li {...props}>{option.name}</li>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Loan Type</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit && !id ? (
                      <Select
                        value={data.loan_type}
                        name="loan_type"
                        onChange={onChangeData}
                        onBlur={() => validateLoanType(data.loan_type)}
                      >
                        {loanTypes.map((option, i) => (
                          <MenuItem key={i} value={option.id}>
                            {option.name.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <b>{data.loan_type?.toUpperCase()}</b>
                    )}
                    {errors.loan_type && edit && (
                      <Typography className="error-text">
                        {errors.loan_type}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Loan Status</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <Select
                        value={selectedStatus}
                        name="status"
                        onChange={(e) => {
                          setSelectedStatus(e.target.value);
                        }}
                        onBlur={() => validateLoanStatus(selectedStatus)}
                      >
                        {[
                          { id: "Requested", name: "Requested" },
                          { id: "Processing", name: "Processing" },
                          { id: "Approved", name: "Approved" },
                          { id: "Rejected", name: "Rejected" },
                        ].map((option, i) => (
                          <MenuItem key={i} value={option.id}>
                            {option.name.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <b>{selectedStatus?.toUpperCase()}</b>
                    )}
                    {errors.status && edit && (
                      <Typography className="error-text">
                        {errors.status}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Bank Name</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="bank_name"
                        value={data.bank_name}
                        onChange={onChangeData}
                        // onBlur={() =>
                        //   validateOfferedAmount(data.bank_name)
                        // }
                      />
                    ) : (
                      <p>{data.bank_name}</p>
                    )}
                    {errors.bank_name && edit && (
                      <Typography className="error-text">
                        {errors.bank_name}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Amount</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="amount"
                        value={data.amount}
                        onChange={onChangeData}
                        // onBlur={() =>
                        //   validateOfferedAmount(data.amount)
                        // }
                      />
                    ) : (
                      <p>{data.amount}</p>
                    )}
                    {errors.amount && edit && (
                      <Typography className="error-text">
                        {errors.amount}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Ref ID</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="reference_id"
                        value={data.reference_id}
                        onChange={onChangeData}
                        // onBlur={() =>
                        //   validateOfferedAmount(data.amount)
                        // }
                      />
                    ) : (
                      <p>{data.reference_id}</p>
                    )}
                    {errors.reference_id && edit && (
                      <Typography className="error-text">
                        {errors.reference_id}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Purpose</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="purpose"
                        value={data.purpose}
                        onChange={onChangeData}
                        multiline
                        rows={2}
                        maxRows={4}
                        // onBlur={() =>
                        //   validateOfferedAmount(data.purpose)
                        // }
                      />
                    ) : (
                      <p>{data.purpose}</p>
                    )}
                    {errors.purpose && edit && (
                      <Typography className="error-text">
                        {errors.purpose}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </Table>
            </Box>
          </Box>
        </Grid>
        <Box component="form" sx={{ ml: 3 }}>
          {id && edit && (
            <>
              <Button
                sx={{ mt: 3 }}
                onClick={addData}
                variant="contained"
                className="primary-btn me-3"
              >
                {" "}
                Update{" "}
              </Button>
              <Link className="text-decoration-none" to={`/loan?id=${id}`}>
                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  className="primary-btn"
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </Link>
            </>
          )}
          {!id && edit && (
            <Button
              sx={{ mt: 3 }}
              onClick={addData}
              variant="contained"
              className="primary-btn"
            >
              Save
            </Button>
          )}
        </Box>
      </Grid>
      {id && (
        <CustomerData
          adminData={adminData}
          id={data?.customer_id}
          edit={null}
          noAccordian
        />
      )}
    </>
  );
}
export default Loan;
