import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import user from "../Assets/Image/user.png";
import { getLocalStorage } from "../Common/cookie";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Button,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton,
  Paper,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import VerifiedIcon from "@mui/icons-material/Verified";
import CustomTable from "../Common/CustomTable";
import { getAdmin } from "../api/admin";
import { deleteCustomer, customers } from "../api/customer";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import path from "path-browserify"
function Customers(props) {
  const { socket } = props;
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [search, setSearch] = useState({
    name: "",
    phone: "",
    phone_verified: "",
    email: "",
    min: "",
    max: "",
  });
  const [data, setData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    socket.on("customer", (newObject) => {
      setData((data) => {
        const filteredArray = data.filter((obj) => obj.id !== newObject.id);
        return [...filteredArray, newObject];
      });
    });
  }, [socket]);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          // if (response.data.type === 'Supar Admin') {
          //     setHideDelete(false)
          // }
          getAllCustomers("");
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  const getAllCustomers = async (search) => {
    const response = await customers({
      token: adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
    });
    if (response?.status === 200) {
      let filtered = response.data;
      if (search.phone) {
        filtered = filtered.filter((d) => {
          return d.phone.match(`.*${search.phone}.*`);
        });
      }
      if (search.name) {
        filtered = filtered.filter((d) => {
          return d.name.toUpperCase().match(`.*${search.name.toUpperCase()}.*`);
        });
      }
      if (search.phone_verified) {
        filtered = filtered.filter((d) => {
          return d.phone_verified === 1;
        });
      }
      if (search.email) {
        filtered = filtered.filter((d) => {
          return d.email
            .toUpperCase()
            .match(`.*${search.email.toUpperCase()}.*`);
        });
      }
      if (search.min) {
        filtered = filtered.filter((d) => {
          return d.loan.length >= search.min;
        });
      }
      if (search.max) {
        filtered = filtered.filter((d) => {
          return d.loan.length <= search.max;
        });
      }
      setData(filtered);
    }
  };

  useEffect(() => {
    getAllCustomers(search);
  }, [search]);

  const viewCustomerData = (data) => {
    navigate("/customer?id=" + data.id);
  };

  const editCustomerData = (data) => {
    navigate("/customer?id=" + data.id + "&edit=1");
  };

  const deleteCustomerData = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCustomer({
          token:
            adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
          admin_id: data.id,
        })
          .then((response) => {
            data.callback();
            if (response?.status === 200) {
              Swal.fire(
                "Good Job!",
                "Employee deleted successfully",
                "success"
              );
              response = undefined;
              getAllCustomers("");
            } else {
              Swal.fire(
                "Oops!",
                "Occuring some error while deleting Customer",
                "error"
              );
              response = undefined;
            }
          })
          .catch((err) => {
            Swal.fire("Oops!", err.response.data.message, "error");
          });
      }
    });
  };

  const deleteAllCustomersData = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let upar = [];
        data.map((d) => {
          if (d !== null) {
            return deleteCustomer({
              token:
                adminData?.accessToken ||
                getLocalStorage("rv-admin").accessToken,
              admin_id: d.id,
            });
          }
        });
        await Promise.all(upar).then(() => {
          Swal.fire("Good Job!", "Customers deleted successfully.", "success");
        });
        getAllCustomers("");
      }
    });
  };

  const createRows = (data) => {
    const headCells = [
      {
        id: "id",
        label: "Id",
      },
      {
        id: "profile",
        label: "Profile",
      },
      {
        id: "name",
        label: "Name",
      },
      {
        id: "phone",
        label: "Phone",
      },
      {
        id: "verified",
        label: "",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "loans",
        label: "Loans",
      },
      // {
      //     id: 'view',
      //     label: '',
      //     type: 'button',
      //     noSort: true,
      //     icon: VisibilityIcon,
      //     iconStyle: { color: "blue" },
      //     callback: viewCustomerData
      // },
      {
        id: "edit",
        label: "",
        type: "button",
        noSort: true,
        icon: EditIcon,
        iconStyle: { color: "blue" },
        callback: editCustomerData,
      },
      // {
      //     id: 'delete',
      //     label: '',
      //     type: 'button',
      //     noSort: true,
      //     icon: CancelIcon,
      //     iconStyle: { color: "red" },
      //     noDisplay: hideDelete,
      //     callback: deleteCustomerData
      // },
      {
        id: "delete_all",
        noDisplay: true,
      },
    ];
    function createData(
      id,
      profile,
      name,
      phone,
      verified,
      email,
      loans,
      link
    ) {
      return {
        id,
        profile,
        name,
        phone,
        verified,
        email,
        loans,
        link,
      };
    }

    const rows = data.map((value) => {
      return createData(
        value.id,
        value.profile ? (
          <img
            // src={`${path.join(
            //   process.env.REACT_APP_API,
            //   "uploads",
            //   "Customer",
            //   value.profile
            // )}`}
            src={`${process.env.REACT_APP_API}/uploads/Customer/${value.profile}`}
            alt="avatar"
            style={{ width: 50, height: 50, borderRadius: "20%" }}
          />
        ) : (
          <img
            src={user}
            alt="avatar"
            style={{ width: 50, height: 50, borderRadius: "20%" }}
          />
        ),
        value.name,
        value.phone,
        value.phone_verified ? <VerifiedIcon /> : null,
        value.email,
        <center>{value.loan.length}</center>,
        `/customer?id=${value.id}`
      );
    });

    const filters = (
      <>
        <TableRow hover tabIndex={-1}>
          <TableCell>
            {clear ? (
              <Tooltip title="Clear Filters" arrow>
                <IconButton onClick={clearFilter}>
                  <CancelIcon className="primary-text" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <TextField
              fullWidth
              variant="outlined"
              name="name"
              value={search.name}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              variant="outlined"
              name="phone"
              value={search.phone}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <Select
              MenuProps={{ autoFocus: false }}
              value={search.phone_verified}
              name="phone_verified"
              onChange={onChangeData}
            >
              {[
                { id: "", name: "All" },
                { id: true, name: "Verified" },
              ].map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              variant="outlined"
              name="email"
              value={search.email}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <TextField
              label="Min"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%", maxWidth: "50px" }}
              variant="outlined"
              name="min"
              value={search.min}
              onChange={onChangeData}
            />
            <TextField
              label="Max"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%", maxWidth: "50px" }}
              variant="outlined"
              name="max"
              value={search.max}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell style={{ width: "34px" }}></TableCell>
        </TableRow>
      </>
    );

    return { headCells, rows, filters };
  };

  function clearFilter() {
    setSearch({
      name: "",
      phone: "",
      phone_verified: "",
      email: "",
      min: "",
      max: "",
    });
  }

  function onChangeData(e) {
    setSearch((search) => ({
      ...search,
      [e.target.name]: e.target.value.toUpperCase(),
    }));
  }

  useEffect(() => {
    let flag = 0;
    Object.keys(search).forEach(function (key) {
      if (search[key] !== "") {
        flag = 1;
      }
    });
    flag === 0 ? setClear(false) : setClear(true);
  }, [search]);

  useEffect(() => {
    let f_data = [];
    if (data.length > 0) {
      data.map((d) => {
        f_data.push({
          id: d.id,
          name: d.name,
          phone: d.phone,
          email: d.email,
          gender: d.gender,
          dob: d.dob
            ? new Date(d.dob)
                .toISOString()
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("-")
            : "",
          aadhar_no: d.aadhar_no,
          pan_no: d.pan_no,
          pincode: d.pincode,
          razorpay_id: d?.bank[0]?.razorpay_id,
        });
      });
    }
    setDownloadData(f_data);
  }, [data]);

  const handleExportRows = () => {
    if (downloadData.length > 0) {
      const doc = new jsPDF();
      const tableData = downloadData.map((row) => Object.values(row));
      const tableHeaders = Object.keys(downloadData[0]);

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });

      doc.save("customers.pdf");
    } else {
      Swal.fire({
        title: "Caution",
        text: "You can not download 0 records.",
        icon: "warning",
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Grid>
            <CSVLink
              data={downloadData}
              filename={"customers.csv"}
              className="btn secondary-btn mr-1"
              target="_blank"
            >
              <DownloadIcon /> CSV
            </CSVLink>
            <Button
              onClick={handleExportRows}
              className="btn secondary-btn"
              variant="outlined"
            >
              <DownloadIcon /> PDF
            </Button>
          </Grid>
          <Link className="text-decoration-none ml-3" to="/customer?edit=1">
            <Button className="primary-btn" variant="contained">
              Add Customer
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box component={Paper} sx={{ pt: 2 }}>
            {/* {data.length > 0 ? */}
            <CustomTable {...createRows(data)} hideDelete={true} />
            {/* :
                            <Box sx={{ p: 3 }}><h5>You have not added any admin.</h5></Box>
                        } */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Customers;
