import Person2Icon from '@mui/icons-material/Person2';
import Groups2Icon from '@mui/icons-material/Groups2';
import HomeIcon from '@mui/icons-material/Home';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SpaIcon from '@mui/icons-material/Spa';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';


export const MenuIcons = (props) => {
    const { icon } = props;
    if (icon === 'HomeIcon') {
        return (
            <HomeIcon className='primary-table-header-text' />
        )
    } else if (icon === 'Person2Icon') {
        return (
            <Person2Icon className='primary-table-header-text' />
        )
    } else if (icon === 'PersonOutlineIcon') {
        return (
            <PersonOutlineIcon className='primary-table-header-text' />
        )
    } else if (icon === 'Groups2Icon') {
        return (
            <Groups2Icon className='primary-table-header-text' />
        )
    } else if (icon === 'CreditScoreIcon') {
        return (
            <CreditScoreIcon className='primary-table-header-text' />
        )
    } else if (icon === 'CreditCardIcon') {
        return (
            <CreditCardIcon className='primary-table-header-text' />
        )
    } else if (icon === 'RequestQuoteIcon') {
        return (
            <RequestQuoteIcon className='primary-table-header-text' />
        )
    } else if (icon === 'SpaIcon') {
        return (
            <SpaIcon className='primary-table-header-text' />
        )
    } else if (icon === 'ContactSupportIcon') {
        return (
            <ContactSupportIcon className='primary-table-header-text' />
        )
    } else if (icon === 'MarkEmailReadIcon') {
        return (
            <MarkEmailReadIcon className='primary-table-header-text' />
        )
    } else if (icon === 'SettingsIcon') {
        return (
            <SettingsIcon className='primary-table-header-text' />
        )
    } else if (icon === 'CurrencyRupeeIcon') {
        return (
            <CurrencyRupeeIcon className='primary-table-header-text' />
        )
    }
}

export const CustomMenu = (props) => {
    const { handleCloseUserMenu, anchorElUser, data, userLogout } = props;
    const navigate = useNavigate();

    function logout() {        
        handleCloseUserMenu()
        userLogout()
        navigate("/login")
    }
    function changePass() {
        handleCloseUserMenu()
        navigate("/changePassword")
    }
    function profile() {
        handleCloseUserMenu()
        navigate("/profile")
    }
    return <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
    >
        <MenuItem onClick={profile} className='primary-text'>
            <ListItemIcon>
                <PersonRoundedIcon fontSize="small" className='primary-text' />
            </ListItemIcon>
           {/* {data.adminData.username} */}Profile
        </MenuItem>
        <MenuItem onClick={changePass} className='primary-text'>
            <ListItemIcon>
                <LockOpenTwoToneIcon fontSize="small" className='primary-text' />
            </ListItemIcon>
            Change Password
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout} className='primary-text'>
            <ListItemIcon>
                <LogoutIcon fontSize="small" className='primary-text' />
            </ListItemIcon>
            Logout
        </MenuItem>
    </Menu>
}