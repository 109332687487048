import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import {
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import { getAdmin } from "../api/admin";
import CustomerData from "../Components/customerData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoanData from "../Components/loanData";
import BankPersonalLoanData from "../Components/bankPersonalLoanData";
import PersonalLoanData from "../Components/personalLoanData";

function Customer(props) {
  const { socket } = props;
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const edit = new URLSearchParams(search).get("edit");

  const [adminData, setAdminData] = useState({});
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Link className="text-decoration-none" to="/customers">
            <Button className="primary-btn" variant="contained">
              {" "}
              Back
            </Button>
          </Link>
          {id && !edit && (
            <Link
              className="text-decoration-none"
              to={`/customer?id=${id}&edit=1`}
            >
              <Button className="primary-btn" variant="contained">
                {" "}
                Edit
              </Button>
            </Link>
          )}
          {id && edit && (
            <Link className="text-decoration-none" to={`/customer?id=${id}`}>
              <Button className="primary-btn" variant="contained">
                {" "}
                Cancel
              </Button>
            </Link>
          )}
        </Grid>
        <div style={{ margin: "16px", marginRight: "0", width: "100%" }}>
          <CustomerData adminData={adminData} id={id} edit={edit} />
        </div>
        {id && (
          <>
            <Typography sx={{ ml: 2, mt: 2, mb: 1 }}>
              <b>Requested Loans:</b>
            </Typography>
            <div style={{ margin: "16px", marginRight: "0", width: "100%" }}>
              <Accordion onChange={handleChange("panel1")}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography sx={{ flexShrink: 0 }}>
                      Personal loans
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <PersonalLoanData
                  socket={socket}
                  adminData={adminData}
                  customerId={id}
                  noEdit
                />
              </Accordion>
              <Accordion onChange={handleChange("panel1")}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography sx={{ flexShrink: 0 }}>
                      Personal loans from Bank
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <BankPersonalLoanData
                  socket={socket}
                  adminData={adminData}
                  customerId={id}
                  noEdit
                />
              </Accordion>
              <Accordion onChange={handleChange("panel1")}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography sx={{ flexShrink: 0 }}>Other loans</Typography>
                  </Grid>
                </AccordionSummary>
                <LoanData
                  socket={socket}
                  adminData={adminData}
                  customerId={id}
                  noEdit
                />
              </Accordion>
            </div>
          </>
        )}
      </Grid>
    </>
  );
}

export default Customer;
