import { verifyPAN } from "../api/cashfreeVerification";

export const validateEmail = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter Email.";
        valid = false;
    } else if (typeof str !== "undefined") {
        if (!str.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            error = "*Please enter Proper Format.";
            valid = false;
        } else {
            valid = true;
        }
    }
    return { error, valid };
}

export const checkPAN = async (data) => {
    let error = "";
    let valid = false;
    let name = "";
    const validPAN = validatePan(data.pan);
    if (validPAN.valid) {
      const response = await verifyPAN({
        data: {
          name: data.name,
          pan: data.pan,
          customer_id: data.id,
        },
      });
      if (response.status === 200) {
        if (response.data.valid) {
          valid = true;
          name = response.data.registered_name || response.data.name_provided;
        } else {
          error = "*Invalid PAN";
          valid = false;
        }
      } else {
        error = "*" + response.message.message;
        valid = false;
      }
      return { error, valid, name };
    } else {
      return { error: validPAN.error, valid: validPAN.valid, name: "" };
    }
  };
  export const validatePan = (str) => {
    let error = "";
    let valid = false;
    if (!str) {
      error = "*Enter PAN no.";
      valid = false;
    } else if (typeof str !== "undefined") {
      if (!String(str).match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)) {
        error = "*Please enter Proper Format.";
        valid = false;
      } else {
        valid = true;
      }
    }
    return { error, valid };
  };

export const validateAadhar = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter Aadhar card no.";
        valid = false;
    } else if (str.length !== 12) {
        error = "*Enter 12 digits OTP.";
        valid = false;
    } else {
        valid = true;
    }
    return { error, valid };
}

export const validateACno = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter Account no.";
        valid = false;
    } else if (typeof str !== "undefined") {
        if (!str.toString().match(/^[0-9]{9,18}$/)) {
            error = "*Please enter Proper Format.";
            valid = false;
        } else {
            valid = true;
        }
    }
    return { error, valid };
}
export const validateSalary = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter something.";
        valid = false;
    } else if(Number(str) < 0){
        error = "*It should be more than 0.";
        valid = false;
    } else {
        valid = true;
    }
    return { error, valid };
}

// export const validateNumber = (str) => {
//     let error = '';
//     let valid = false;
//     if (!str) {
//         error = "*Enter something.";
//         valid = false;
//     } else if(Number(str) < 0){
//         error = "*It should be more than 0.";
//         valid = false;
//     } else {
//         valid = true;
//     }
//     return { error, valid };
// }

export const validateIFSC = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter IFSC code.";
        valid = false;
    } else if (typeof str !== "undefined") {
        if (!str.match(/^[A-Za-z]{4}\d{7}$/)) {
            error = "*Please enter Proper Format.";
            valid = false;
        } else {
            valid = true;
        }
    }
    return { error, valid };
}

export const validateText = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter something.";
        valid = false;
    } else {
        valid = true;
    }
    return { error, valid };
}

export const validatePincode = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter Pincodecode.";
        valid = false;
    } else if (str.length !== 6) {
        error = "*Enter 6 digits Pincode.";
        valid = false;
    } else {
        valid = true;
    }
    return { error, valid };
}

export const validateDateOfBirth = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter DOB.";
        valid = false;
    } else if (typeof str !== "undefined") {
        const dob = new Date(str);
        const currentDate = new Date();
        const ageInMilliseconds = currentDate - dob;
        const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
        if (ageInYears < 14) {
            error = "*Age should be minimum 14 yrs.";
            valid = false;
        } else {
            valid = true;
        }
    }
    return { error, valid };
}

export const validateDate = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter Date.";
        valid = false;
    } else if (typeof str !== "undefined") {
        const date = new Date(str);
        const currentDate = new Date();
        if (date <= currentDate) {
            error = "*Date should be in future.";
            valid = false;
        } else {
            valid = true;
        }
    }
    return { error, valid };
}

export const validateUname = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter Something.";
        valid = false;
    } else {
        valid = true;
    }
    return { error, valid };
}

export const validateSelect = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Select something.";
        valid = false;
    } else {
        valid = true;
    }
    return { error, valid };
}

export const validatePass = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter password.";
        valid = false;
    } else if (str.length < 6) {
        error = "*Enter atleast 6 character long password.";
        valid = false;
    } else {
        valid = true;
    }
    return { error, valid };
}

export const validateCPass = (str, pass) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter confirm password.";
        valid = false;
    } else if (str.length < 6) {
        error = "*Enter atleast 6 character long password.";
        valid = false;
    } else if (str !== pass) {
        error = "*Confirm password is mismatched.";
        valid = false;
    } else {
        valid = true;
    }
    return { error, valid };
}

export const validateOTP = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter OTP.";
        valid = false;
    } else if (str.length !== 6) {
        error = "*Enter 6 digits OTP.";
        valid = false;
    } else {
        valid = true;
    }
    return { error, valid };
}

export const validateName = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter Name.";
        valid = false;
    } else if (typeof str !== "undefined") {
        if (!str.match(/^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/)) {
            error = "*Please enter alphabets only.";
            valid = false;
        } else {
            valid = true;
        }
    }
    return { error, valid };
}

export const validateTitle = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter Name.";
        valid = false;
    } else if (typeof str !== "undefined") {
        if (!str.match(/^[A-Za-z.À-ÖØ-öø-ÿ' -]+$/)) {
            error = "*Please enter alphabets only.";
            valid = false;
        } else {
            valid = true;
        }
    }
    return { error, valid };
}

export const validateContact = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter Contact number.";
        valid = false;
    } else if (typeof str !== "undefined") {
        const regex = /^\d{10}$/;
        if (!regex.test(str)) {
            error = "*Please enter 10 digits only.";
            valid = false;
        } else {
            valid = true;
        }
    }
    return { error, valid };
}

export const validateMin = (str) => {
    let error = '';
    let valid = false;
    if (!str) {
        error = "*Enter Minimum.";
        valid = false;
    } else if (typeof str !== "undefined") {        
        if (Number(str) < 0) {
            error = "*Please enter posotive number.";
            valid = false;
        } else {
            valid = true;
        }
    }
    return { error, valid };
}

export const validateMax = (min, max) => {
    let error = '';
    let valid = false;
    if (!max) {
        error = "*Enter Maximum.";
        valid = false;
    } else if (typeof max !== "undefined") {        
        if (Number(max) < 0) {
            error = "*Please enter posotive number.";
            valid = false;
        } else if (Number(max) < Number(min)) {
            error = "*Please enter more than minimum.";
            valid = false;
        } else {
            valid = true;
        }
    }
    return { error, valid };
}