import axios from "axios";

export const checkBankDetails = async (obj) => {
  return axios
    .post("/cashfree-verification/bank-account", obj.data, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${obj.token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message.message);
      return err.response.data;
    });
};

export const verifyPAN = (obj) => {
  return axios
    .post("/cashfree-verification/pan", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};