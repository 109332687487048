import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton,
  Paper,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import CustomTable from "../Common/CustomTable";
import { getLocalStorage } from "../Common/cookie";
import { getAdmin } from "../api/admin";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { convertUTCToIST } from "../Common/fun";
import { dateWiseCollectionList } from "../api/collection";

function Collections(props) {
  const { socket } = props;
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [search, setSearch] = useState({
    startDate: 0,
    endDate: 0,
    minAmount: 0,
    maxAmount: 0,
  });
  const [data, setData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          getAllCollections()
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  const viewCollectionData = (data) => {
    navigate("/collections?id=" + data.id);
  };
  const getAllCollections = async () => {
    const response = await dateWiseCollectionList({
      token: adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
    });
    if (response?.status === 200) {
      let filtered = response.data;
      if (search.minAmount) {
        filtered = filtered.filter((d) => {
          return d.total_collection > search.minAmount;
        });
      }
      if (search.maxAmount) {
        filtered = filtered.filter((d) => {
          return d.total_collection < search.maxAmount;
        });
      }
      if (search.startDate) {
        filtered = filtered.filter((d) => {
          return new Date(d.pay_date) >= new Date(search.startDate);
        });
      }
      if (search.endDate) {
        filtered = filtered.filter((d) => {
          return new Date(d.pay_date) <= new Date(search.endDate);
        });
      }
      setData(filtered);
    }
  };

  useEffect(() => {
    getAllCollections();
  }, [search]);

  const editCollectionData = (data) => {
    navigate("/collections?id=" + data.id + "&edit=1");
  };

  const createRows = (data) => {
    const headCells = [
      {
        id: "date",
        label: "Date",
      },
      {
        id: "collection",
        label: "Collection",
      },
      {
        id: "view",
        label: "",
        type: "button",
        noSort: true,
        icon: VisibilityIcon,
        iconStyle: { color: "blue" },
        callback: viewCollectionData,
      },
      // {
      //     id: "edit",
      //     label: "",
      //     type: "button",
      //     noSort: true,
      //     icon: EditIcon,
      //     iconStyle: { color: "blue" },
      //     noDisplay: false,
      //     callback: editCollectionData,
      //   },
      {
        id: "delete_all",
        noDisplay: true,
      },
    ];
    function createData(date, collection) {
      return {
        date,
        collection,
        
      };
    }

    const rows = data.map((value) => {
      return createData(
        new Date(convertUTCToIST(value.pay_date)).toLocaleDateString(),
        value.total_collection,
        // `/collections?id=${value.id}`
      );
    });

    const filters = (
      <>
        <TableRow hover tabIndex={-1}>
          <TableCell>
            {clear ? (
              <Tooltip title="Clear Filters" arrow>
                <IconButton onClick={clearFilter}>
                  <CancelIcon className="primary-text" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell></TableCell>          
          <TableCell>
            <input
              style={{ width: "45%" }}
              type="date"
              name="startDate"
              max={new Date().toISOString().split("T")[0]}
              value={search.startDate && search.startDate.split("T")[0]}
              onChange={onChangeData}
            />
            <span style={{ marginLeft: "4%", marginRight: "4%" }}>-</span>
            <input
              style={{ width: "45%" }}
              type="date"
              name="endDate"
              value={search.endDate && search.endDate.split("T")[0]}
              min={search.startDate && search.startDate}
              max={new Date().toISOString().split("T")[0]}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <TextField
              style={{ width: "45%" }}
              variant="outlined"
              type="number"
              name="minAmount"
              value={search.minAmount}
              onChange={onChangeData}
            />
            <span style={{ marginLeft: "4%", marginRight: "4%" }}>-</span>
            <TextField
              style={{ width: "45%" }}
              variant="outlined"
              type="number"
              name="maxAmount"
              value={search.maxAmount}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell style={{ width: "34px" }}></TableCell>
        </TableRow>
      </>
    );

    return { headCells, rows, filters };
  };

  function clearFilter() {
    setSearch({      
      startDate: 0,
      endDate: 0,
      minAmount: 0,
      maxAmount: 0
    });
  }

  function onChangeData(e) {
    if (e.target.name === "startDate" && search.endDate !== null) {
      setSearch((search) => ({
        ...search,
        endDate: null,
      }));
    }
    if (e.target.name === "endDate" && search.startDate === null) {
      setSearch((search) => ({
        ...search,
        startDate: e.target.value,
      }));
    }
    setSearch((search) => ({
      ...search,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    let flag = 0;
    Object.keys(search).forEach(function (key) {
      if (search[key] !== "" && search[key] !== 0) {
        flag = 1;
      }
    });
    flag === 0 ? setClear(false) : setClear(true);
  }, [search]);

  useEffect(() => {
    let f_data = [];
    if (data.length > 0) {
      data.map((d) => {
        f_data.push({
          pay_date: new Date(d.pay_date)
            .toISOString()
            .replace(/T.*/, "")
            .split("-")
            .reverse()
            .join("-"),
          total_collection: d.total_collection,
        });
      });
    }
    setDownloadData(f_data);
  }, [data]);

  const handleExportRows = () => {
    if (downloadData.length > 0) {
      const doc = new jsPDF();
      const tableData = downloadData.map((row) => Object.values(row));
      const tableHeaders = Object.keys(downloadData[0]);

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });

      doc.save("collections.pdf");
    } else {
      Swal.fire({
        title: "Caution",
        text: "You can not download 0 records.",
        icon: "warning",
      });
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="d-flex justify-content-start">
          <Grid>
            <CSVLink
              data={downloadData}
              filename={"collections.csv"}
              className="btn secondary-btn mr-1"
              target="_blank"
            >
              <DownloadIcon /> CSV
            </CSVLink>
            <Button
              onClick={handleExportRows}
              className="btn secondary-btn"
              variant="outlined"
            >
              <DownloadIcon /> PDF
            </Button>
          </Grid>
          {/* <Link className='text-decoration-none' to='/collections?edit=1' style={{ visibility: "hidden" }}><Button className='primary-btn' variant="contained" > Send Mail</Button></Link> */}
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box component={Paper} sx={{ pt: 2 }}>
            {/* {data.length > 0 ? */}
            <CustomTable {...createRows(data)} hideDelete={true} />
            {/* :
                            <Box sx={{ p: 3 }}><h5>You have not added any admin.</h5></Box>
                        } */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Collections;
