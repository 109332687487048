import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton,
  Paper,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import CustomTable from "../Common/CustomTable";
import { insurances } from "../api/insurance";
import { getLocalStorage } from "../Common/cookie";
import { getAdmin } from "../api/admin";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { convertUTCToIST } from "../Common/fun";

function Insurances(props) {
  const { socket } = props;
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [search, setSearch] = useState({
    email: "",
    name: "",
    phone: "",
    type: "",
    startDate: 0,
    endDate: 0,
  });
  const [data, setData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    socket.on("insurance", (newObject) => {
      setData((data) => {
        const filteredArray = data.filter((obj) => obj.id !== newObject.id);
        return [...filteredArray, newObject];
      });
    });
  }, [socket]);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          getAllInsurances("");
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  const viewInsuranceData = (data) => {
    navigate("/insurance?id=" + data.id);
  };
  const getAllInsurances = async (search) => {
    const response = await insurances({
      token:
        adminData?.accessToken ||
        getLocalStorage("rv-admin").accessToken ||
        getLocalStorage("rv-admin").accessToken,
    });
    if (response?.status === 200) {
      let filtered = response.data;
      if (search.email) {
        filtered = filtered.filter((d) => {
          return d.customer.email
            ?.toUpperCase()
            ?.match(`.*${search.email.toUpperCase()}.*`);
        });
      }
      if (search.name) {
        filtered = filtered.filter((d) => {
          return d.customer.name
            ?.toUpperCase()
            ?.match(`.*${search.name.toUpperCase()}.*`);
        });
      }
      if (search.phone) {
        filtered = filtered.filter((d) => {
          return d.customer.phone?.match(`.*${search.phone}.*`);
        });
      }
      if (search.type) {
        filtered = filtered.filter((d) => {
          return d.type.toUpperCase() === search.type.toUpperCase();
        });
      }
      if (search.startDate) {
        filtered = filtered.filter((d) => {
          return new Date(d.created_at) >= new Date(search.startDate);
        });
      }
      if (search.endDate) {
        filtered = filtered.filter((d) => {
          return new Date(d.created_at) <= new Date(search.endDate);
        });
      }
      setData(filtered);
    }
  };

  useEffect(() => {
    getAllInsurances(search);
  }, [search]);

  const editInsuranceData = (data) => {
    navigate("/insurance?id=" + data.id + "&edit=1");
  };

  const createRows = (data) => {
    const headCells = [
      {
        id: "id",
        label: "Id",
      },
      {
        id: "name",
        label: "Name",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "phone",
        label: "Phone",
      },
      {
        id: "type",
        label: "Type",
      },
      {
        id: "created_at",
        label: "Applied On",
      },
      {
        id: "view",
        label: "",
        type: "button",
        noSort: true,
        icon: VisibilityIcon,
        iconStyle: { color: "blue" },
        callback: viewInsuranceData,
      },
      // {
      //     id: "edit",
      //     label: "",
      //     type: "button",
      //     noSort: true,
      //     icon: EditIcon,
      //     iconStyle: { color: "blue" },
      //     noDisplay: false,
      //     callback: editInsuranceData,
      //   },
      {
        id: "delete_all",
        noDisplay: true,
      },
    ];
    function createData(id, name, email, phone, type, created_at, link) {
      return {
        id,
        name,
        email,
        phone,
        type,
        created_at,
        link,
      };
    }

    const rows = data.map((value) => {
      return createData(
        value.id,
        value.customer.name,
        value.customer.email,
        value.customer.phone,
        value.type,
        new Date(convertUTCToIST(value.created_at)).toLocaleDateString(),
        `/insurance?id=${value.id}`
      );
    });

    const filters = (
      <>
        <TableRow hover tabIndex={-1}>
          <TableCell>
            {clear ? (
              <Tooltip title="Clear Filters" arrow>
                <IconButton onClick={clearFilter}>
                  <CancelIcon className="primary-text" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <TextField
              fullWidth
              variant="outlined"
              name="name"
              value={search.name}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              variant="outlined"
              name="email"
              value={search.email}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              variant="outlined"
              name="phone"
              value={search.phone}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <Select
              MenuProps={{ autoFocus: false }}
              value={search.type}
              name="type"
              onChange={onChangeData}
            >
              {[
                { id: "", name: "All" },
                { id: "Life", name: "Life" },
                { id: "Health", name: "Health" },
                { id: "Car", name: "Car" },
              ].map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <input
              style={{ width: "45%" }}
              type="date"
              name="startDate"
              max={new Date().toISOString().split("T")[0]}
              value={search.startDate && search.startDate.split("T")[0]}
              onChange={onChangeData}
            />
            <span style={{ marginLeft: "4%", marginRight: "4%" }}>-</span>
            <input
              style={{ width: "45%" }}
              type="date"
              name="endDate"
              value={search.endDate && search.endDate.split("T")[0]}
              min={search.startDate && search.startDate}
              max={new Date().toISOString().split("T")[0]}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell style={{ width: "34px" }}></TableCell>
        </TableRow>
      </>
    );

    return { headCells, rows, filters };
  };

  function clearFilter() {
    setSearch({
      email: "",
      name: "",
      phone: "",
      type: "",
      startDate: 0,
      endDate: 0,
    });
  }

  function onChangeData(e) {
    if (e.target.name === "startDate" && search.endDate !== null) {
      setSearch((search) => ({
        ...search,
        endDate: null,
      }));
    }
    if (e.target.name === "endDate" && search.startDate === null) {
      setSearch((search) => ({
        ...search,
        startDate: e.target.value,
      }));
    }

    setSearch((search) => ({
      ...search,
      [e.target.name]: e.target.value.toUpperCase(),
    }));
  }

  useEffect(() => {
    let flag = 0;
    Object.keys(search).forEach(function (key) {
      if (search[key] !== "" && search[key] !== 0) {
        flag = 1;
      }
    });
    flag === 0 ? setClear(false) : setClear(true);
  }, [search]);

  useEffect(() => {
    let f_data = [];
    if (data.length > 0) {
      data.map((d) => {
        f_data.push({
          id: d.id,
          type: d.type,
          customer_id: d.customer.id,
          phone: d.customer.phone,
          name: d.customer.name,
          start_date: new Date(d.start_date)
            .toISOString()
            .replace(/T.*/, "")
            .split("-")
            .reverse()
            .join("-"),
          amount: d.amount,
          premium: d.premium,
        });
      });
    }
    setDownloadData(f_data);
  }, [data]);

  const handleExportRows = () => {
    if (downloadData.length > 0) {
      const doc = new jsPDF();
      const tableData = downloadData.map((row) => Object.values(row));
      const tableHeaders = Object.keys(downloadData[0]);

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });

      doc.save("insurances.pdf");
    } else {
      Swal.fire({
        title: "Caution",
        text: "You can not download 0 records.",
        icon: "warning",
      });
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="d-flex justify-content-start">
          <Grid>
            <CSVLink
              data={downloadData}
              filename={"insurances.csv"}
              className="btn secondary-btn mr-1"
              target="_blank"
            >
              <DownloadIcon /> CSV
            </CSVLink>
            <Button
              onClick={handleExportRows}
              className="btn secondary-btn"
              variant="outlined"
            >
              <DownloadIcon /> PDF
            </Button>
          </Grid>
          {/* <Link className='text-decoration-none' to='/insurance?edit=1' style={{ visibility: "hidden" }}><Button className='primary-btn' variant="contained" > Send Mail</Button></Link> */}
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box component={Paper} sx={{ pt: 2 }}>
            {/* {data.length > 0 ? */}
            <CustomTable {...createRows(data)} hideDelete={true} />
            {/* :
                            <Box sx={{ p: 3 }}><h5>You have not added any admin.</h5></Box>
                        } */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Insurances;
