import {
  AccordionDetails,
  Button,
  Grid,
  Switch,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  validateACno,
  validateIFSC,
  validateText,
} from "../Common/validations";
import { bankDetails, verifyBank } from "../api/customerAuth";
import Swal from "sweetalert2";
import { getLocalStorage } from "../Common/cookie";
import { useNavigate } from "react-router-dom";
import {
  sendEmailPersonalLoan,
  updatePersonalLoanStatus,
} from "../api/personalLoan";
function CustBank(props) {
  const {
    adminData,
    edit,
    data,
    setData,
    getCustData,
    loanData,
    setLoanData,
    getPLdata,
  } = props;
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [cusSatus, setCusStatus] = useState({
    admin_id: null,
    admin_name: "",
  });
  const [bankData, setBankData] = useState({
    ac_no: "",
    bank_name: "",
    branch_name: "",
    ifsc_code: "",
    razorpay_id: "",
  });

  function onChangeData(e) {
    setBankData((bankData) => ({
      ...bankData,
      [e.target.name]: e.target.value.toUpperCase(),
    }));
  }

  useEffect(() => {
    const asyncFn = async () => {
      if (data?.bank?.[0]) {
        setBankData(data.bank[0]);
      }
    };
    asyncFn();
  }, [data]);

  useEffect(() => {
    if (loanData?.id) {
      const addItem = loanData.status.find(
        (item) => item.name === "Bank Details Verification"
      );
      setCusStatus(addItem);
    }
  }, [loanData]);

  async function handleChange() {
    await updatePersonalLoanStatus({
      data: {
        loan_id: loanData.id,
        status_name: "Bank Details Verification",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        approved: 1,
      },
      token: adminData.accessToken,
    });
    await verifyBank({
      data: {
        id: bankData.id,
        verified: 1,
      },
      token: adminData.accessToken,
    });
    await getCustData();
    await getPLdata(loanData.id, adminData);
  }
  async function sendEmail() {
    await sendEmailPersonalLoan({
      data: {
        loan_id: loanData.id,
        email: data.email,
        status_name: "Bank Details Verification",
        extra_info: "",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        link: `${process.env.REACT_APP_FE}/apply-now/Instant/bank?id=${loanData.id}&verify=1`
      },
      token: adminData.accessToken,
    });
    await verifyBank({
      data: {
        id: bankData.id,
        verified: 0,
      },
      token: adminData.accessToken,
    });
    await getCustData();
    await getPLdata(loanData.id, adminData);
  }

  function validateAC(str) {
    setErrors((errors) => ({
      ...errors,
      ac_no: validateACno(str).error,
    }));
  }
  function validateBankNAme(str) {
    setErrors((errors) => ({
      ...errors,
      bank_name: validateText(str).error,
    }));
  }
  function validateBranchName(str) {
    setErrors((errors) => ({
      ...errors,
      branch_name: validateText(str).error,
    }));
  }
  function validateIfsc(str) {
    setErrors((errors) => ({
      ...errors,
      ifsc_code: validateIFSC(str).error,
    }));
  }

  function validateForm() {
    validateAC(bankData.ac_no);
    validateBankNAme(bankData.bank_name);
    validateBranchName(bankData.branch_name);
    validateIfsc(bankData.ifsc_code);
    let valid =
      validateACno(bankData.ac_no).valid &&
      validateText(bankData.bank_name).valid &&
      validateText(bankData.branch_name).valid &&
      validateIFSC(bankData.ifsc_code).valid;
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (validateForm()) {
      const response = await bankDetails({
        token:
          adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
        data: {
          id: data.id,
          ac_no: bankData.ac_no.toString(),
          bank_name: bankData.bank_name,
          branch_name: bankData.branch_name,
          ifsc_code: bankData.ifsc_code,
        },
      });
      if (!response?.data?.id) {
        Swal.fire("Oops...", response?.data?.data?.message, "error");
      } else {
        Swal.fire("Good Job!", "Customer data updated successfully", "success");
        getCustData();
      }
    }
  }

  return (
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Account No</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="ac_no"
                    value={bankData.ac_no}
                    onChange={onChangeData}
                    onBlur={() => validateAC(bankData.ac_no)}
                  />
                ) : (
                  bankData.ac_no
                )}
                {errors.ac_no && edit && (
                  <Typography className="error-text">{errors.ac_no}</Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>IFSC Code</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="ifsc_code"
                    value={bankData.ifsc_code}
                    onChange={onChangeData}
                    onBlur={() => validateIfsc(bankData.ifsc_code)}
                  />
                ) : (
                  bankData.ifsc_code
                )}
                {errors.ifsc_code && edit && (
                  <Typography className="error-text">
                    {errors.ifsc_code}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Bank Name</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    value={bankData.bank_name}
                    name="bank_name"
                    onChange={onChangeData}
                    onBlur={() => validateBankNAme(bankData.bank_name)}
                  />
                ) : (
                  bankData.bank_name
                )}
                {errors.bank_name && edit && (
                  <Typography className="error-text">
                    {errors.bank_name}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Branch Name</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    value={bankData.branch_name}
                    name="branch_name"
                    onChange={onChangeData}
                    onBlur={() => validateBranchName(bankData.branch_name)}
                  />
                ) : (
                  bankData.branch_name
                )}
                {errors.branch_name && edit && (
                  <Typography className="error-text">
                    {errors.branch_name}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Razorpay ID</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    value={bankData.razorpay_id}
                    name="razorpay_id"
                    onChange={onChangeData}
                  />
                ) : (
                  bankData.razorpay_id
                )}
                {errors.razorpay_id && edit && (
                  <Typography className="error-text">
                    {errors.razorpay_id}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </Table>
          {edit && (
            <Button
              className="primary-btn"
              variant="contained"
              onClick={saveData}
            >
              {" "}
              Save
            </Button>
          )}
          {loanData?.id && (
            <>
              {cusSatus?.admin_name && cusSatus?.approved === 1 ? (
                <p
                  style={{
                    marginLeft: "5px",
                    fontSize: "13px",
                    fontStyle: "italic",
                  }}
                >
                  Details verified by <b>{cusSatus.admin_name}</b>
                </p>
              ) : (
                <>
                  <p style={{ marginLeft: "5px", marginTop: "6px" }}>
                    Verify details:
                  </p>
                  <Switch
                    style={{ color: "#002d5b" }}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Button
                    sx={{ ml: 4 }}
                    className="primary-btn"
                    variant="contained"
                    onClick={sendEmail}
                  >
                    Send mail
                  </Button>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </AccordionDetails>
  );
}
export default CustBank;
