import {
  AccordionDetails,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useEffect, useState } from "react";
import {
  checkPAN,
  validateAadhar,
  validateDateOfBirth,
  validateEmail,
  validateName,
  validatePan,
  validateSelect,
} from "../Common/validations";
import { personalDetails, verifyPersonal } from "../api/customerAuth";
import user from "../Assets/Image/user.png";
import { getLocalStorage } from "../Common/cookie";
import Swal from "sweetalert2";
import {
  sendEmailPersonalLoan,
  updatePersonalLoanStatus,
} from "../api/personalLoan";
import { verifyPAN } from "../api/cashfreeVerification";
import path from "path-browserify";

function CustPersonal(props) {
  const {
    adminData,
    edit,
    data,
    setData,
    getCustData,
    loanData,
    setLoanData,
    getPLdata,
  } = props;
  const [image, setImage] = useState({ preview: "", raw: "" }); //for profile image
  const [gender, setGender] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [errors, setErrors] = useState({});
  const [cusSatus, setCusStatus] = useState({
    admin_id: null,
    admin_name: "",
  });

  useEffect(() => {
    const asyncFn = async () => {
      if (
        data.phone &&
        data.phone.length === 10 &&
        data.pan_no &&
        data.pan_no.length === 10
      ) {
        const response = await checkPAN({
          name: data?.name || "name",
          pan: data.pan_no,
          id: data?.id || null,
        });
        setData((data) => ({
          ...data,
          name: response.name,
        }));
        setErrors((errors) => ({
          ...errors,
          pan_no: response.error,
        }));
      }
    };

    asyncFn();
  }, [data.pan_no, data.phone]);

  async function onChangeData(e) {
    if (e.target.name === "pan_no") {
      if (e.target.value.length <= 10) {
        setData((data) => ({
          ...data,
          pan_no: e.target.value.toUpperCase(),
        }));
      } else {
        return false;
      }
    } else {
      setData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    }
  }

  useEffect(() => {
    setGender(data.gender);
    setMaritalStatus(data.marital_status);
  }, [data.gender, data.marital_status]);

  useEffect(() => {
    if (loanData?.id) {
      const addItem = loanData.status.find(
        (item) => item.name === "Customer Data Verification"
      );
      setCusStatus(addItem);
    }
  }, [loanData]);

  const handleEditClick = () => {
    document.getElementById("upload-input").click();
  };

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  function validateEmailAddr(str) {
    setErrors((errors) => ({
      ...errors,
      email: validateEmail(str).error,
    }));
  }
  function validateFName(str) {
    setErrors((errors) => ({
      ...errors,
      name: validateName(str).error,
    }));
  }
  function validateDOB(str) {
    setErrors((errors) => ({
      ...errors,
      dob: validateDateOfBirth(str).error,
    }));
  }
  function validateGender(str) {
    setErrors((errors) => ({
      ...errors,
      gender: validateSelect(str).error,
    }));
  }
  function validateMaritalStatus(str) {
    setErrors((errors) => ({
      ...errors,
      marital_status: validateSelect(str).error,
    }));
  }
  function validateAadharNo(str) {
    setErrors((errors) => ({
      ...errors,
      aadhar_no: validateAadhar(str).error,
    }));
  }
  function validatePanNo(str) {
    setErrors((errors) => ({
      ...errors,
      pan_no: validatePan(str).error,
    }));
  }

  function validateForm() {
    validateEmailAddr(data.email);
    validateFName(data.name);
    validateDOB(data.dob);
    validateGender(gender);
    validateMaritalStatus(maritalStatus);
    validateAadharNo(data.aadhar_no);
    validatePanNo(data.pan_no);
    let valid =
      validateEmail(data.email).valid &&
      validateName(data.name).valid &&
      validateDateOfBirth(data.dob).valid &&
      validateSelect(gender).valid &&
      validateSelect(maritalStatus).valid &&
      validateAadhar(data.aadhar_no).valid &&
      validatePan(data.pan_no).valid;
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append("id", data.id || getLocalStorage("rv-user")?.id);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("dob", data.dob.substring(0, 10));
      formData.append("gender", gender);
      formData.append("aadhar_no", data.aadhar_no);
      formData.append("pan_no", data.pan_no);
      formData.append("marital_status", maritalStatus);
      formData.append("photo", image.raw ? image.raw : null);

      const response = await personalDetails({
        token:
          adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
        data: formData,
      });
      if (response?.status === 200) {
        Swal.fire("Good Job!", "Customer data updated successfully", "success");
        getCustData();
      }
    }
  }

  async function handleChange() {
    await updatePersonalLoanStatus({
      data: {
        loan_id: loanData.id,
        status_name: "Customer Data Verification",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        approved: 1,
      },
      token: adminData.accessToken,
    });
    await verifyPersonal({
      data: {
        id: data.id,
        verified: 1,
      },
      token: adminData.accessToken,
    });
    await getCustData();
    await getPLdata(loanData.id, adminData);
  }
  async function sendEmail() {
    await sendEmailPersonalLoan({
      data: {
        loan_id: loanData.id,
        email: data.email,
        status_name: "Customer Data Verification",
        extra_info: "",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        link: `${process.env.REACT_APP_FE}/apply-now/Instant/Basics?id=${loanData.id}`,
      },
      token: adminData.accessToken,
    });
    await verifyPersonal({
      data: {
        id: data.id,
        verified: 0,
      },
      token: adminData.accessToken,
    });
    await getCustData();
    await getPLdata(loanData.id, adminData);
  }
  return (
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12}>
          <Table>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Contact No.</TableCell>
              <TableCell style={{ width: "80%" }}>
                <>
                  {data.phone} {data.phone_verified ? <VerifiedIcon /> : null}
                </>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Name</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    disabled
                    name="name"
                    value={data.name}
                    onChange={onChangeData}
                    onBlur={() => validateFName(data.name)}
                  />
                ) : (
                  data.name
                )}
                {errors.name && edit && (
                  <Typography className="error-text">{errors.name}</Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>PAN</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="pan_no"
                    value={data.pan_no}
                    onChange={onChangeData}
                    onBlur={() => validatePanNo(data.f_name)}
                  />
                ) : (
                  data.pan_no
                )}
                {errors.pan_no && (
                  <Typography className="error-text">
                    {errors.pan_no}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Email</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="email"
                    value={data.email}
                    onChange={onChangeData}
                    onBlur={() => validateEmailAddr(data.email)}
                  />
                ) : (
                  data.email
                )}
                {errors.email && edit && (
                  <Typography className="error-text">{errors.email}</Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>DOB</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <input
                    type="date"
                    name="dob"
                    value={data.dob && data.dob.split("T")[0]}
                    onChange={onChangeData}
                    onBlur={() => validateDateOfBirth(data.dob)}
                  />
                ) : data?.dob ? (
                  new Date(data.dob).toLocaleDateString("en-GB")
                ) : null}
                {errors.dob && edit && (
                  <Typography className="error-text">{errors.dob}</Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Gender</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <Select
                    MenuProps={{ autoFocus: false }}
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                    onBlur={() => validateGender(gender)}
                  >
                    {[
                      { id: "female", name: "FEMALE" },
                      { id: "male", name: "MALE" },
                      { id: "other", name: "OTHER" },
                    ].map((option, i) => (
                      <MenuItem key={i} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  data.gender && data.gender.toUpperCase()
                )}
                {errors.gender && edit && (
                  <Typography className="error-text">
                    {errors.gender}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Marital Status</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <Select
                    MenuProps={{ autoFocus: false }}
                    value={maritalStatus}
                    onChange={(e) => {
                      setMaritalStatus(e.target.value);
                    }}
                    onBlur={() => validateMaritalStatus(maritalStatus)}
                  >
                    {[
                      { id: "married", name: "MARRIED" },
                      { id: "unmarried", name: "UNMARRIED" },
                    ].map((option, i) => (
                      <MenuItem key={i} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  data.marital_status && data.marital_status.toUpperCase()
                )}
                {errors.marital_status && edit && (
                  <Typography className="error-text">
                    {errors.marital_status}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Aadhar No</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="aadhar_no"
                    value={data.aadhar_no}
                    onChange={onChangeData}
                    onBlur={() => validateAadharNo(data.aadhar_no)}
                  />
                ) : (
                  data.aadhar_no
                )}
                {errors.aadhar_no && (
                  <Typography className="error-text">
                    {errors.aadhar_no}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </Table>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box
            component={Paper}
            className="image-preview"
            style={{
              width: "100%",
              maxWidth: "300px",
              height: "auto",
              maxHeight: "380px",
              position: "relative",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            {image.preview ? (
              <img
                src={image.preview}
                alt="Preview"
                style={{ width: "100%", height: "100%" }}
              />
            ) : data.profile ? (
              <img
                // src={`${path.join(
                //   process.env.REACT_APP_API,
                //   "uploads",
                //   "Customer",
                //   data.profile
                // )}`}
                src={`${process.env.REACT_APP_API}/uploads/Customer/${data.profile}`}
                alt="Preview"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <img
                src={user}
                alt="Preview"
                style={{ width: "100%", height: "100%" }}
              />
            )}
            {edit && (
              <div
                className="edit-icon"
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  cursor: "pointer",
                  background: "rgba(255, 255, 255, 0.8)",
                  padding: "5px",
                }}
                onClick={handleEditClick}
              >
                <CameraAltIcon className="primary-text" />
              </div>
            )}
          </Box>
          <input
            type="file"
            id="upload-input"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </Grid>
        {edit && (
          <Button
            sx={{ ml: 2 }}
            className="primary-btn"
            variant="contained"
            onClick={saveData}
          >
            {" "}
            Save
          </Button>
        )}
        {loanData?.id && (
          <>
            {cusSatus?.admin_name && cusSatus?.approved === 1 ? (
              <p
                style={{
                  marginLeft: "25px",
                  fontSize: "13px",
                  fontStyle: "italic",
                }}
              >
                Details verified by <b>{cusSatus.admin_name}</b>
              </p>
            ) : (
              <>
                <p style={{ marginLeft: "25px", marginTop: "6px" }}>
                  Verify details:
                </p>
                <Switch
                  style={{ color: "#002d5b" }}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Button
                  sx={{ ml: 4 }}
                  className="primary-btn"
                  variant="contained"
                  onClick={sendEmail}
                >
                  Send mail
                </Button>
              </>
            )}
          </>
        )}
      </Grid>
    </AccordionDetails>
  );
}
export default CustPersonal;
