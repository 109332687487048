import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import user from "../Assets/Image/user.png";
import {
  Button,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton,
  Paper,
  Box,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";
import { getAdmin } from "../api/admin";
import CustomTable from "../Common/CustomTable";
import { adminForgotSetPassword } from "../api/adminAuth";
import { getLocalStorage } from "../Common/cookie";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  personalLoanCollection,
  personalLoanSendMail,
} from "../api/collection";
import EmailIcon from "@mui/icons-material/Email";

function LoanCollections(props) {
  const { socket } = props;
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [hideDelete, setHideDelete] = useState(true);
  const [search, setSearch] = useState({
    id: "",
    loan_id: "",
    minAmount: 0,
    maxAmount: 0,
    startDate: 0,
    endDate: 0,
  });
  const [data, setData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    socket.on("admin", (newObject) => {
      setData((data) => {
        const filteredArray = data.filter((obj) => obj.id !== newObject.id);
        return [...filteredArray, newObject];
      });
    });
  }, [socket]);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          if (response.data.type === "Supar Admin") {
            setHideDelete(false);
          }
          getAllDueDateCollections("");
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  const getAllDueDateCollections = async (search) => {
    const response = await personalLoanCollection({
      token: adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
    });
    if (response?.status === 200) {
      let filtered = response.data;
      if (search.loan_id) {
        filtered = filtered.filter((d) => {
          return d.loan_id.match(`.*${search.loan_id}.*`);
        });
      }
      if (search.minAmount) {
        filtered = filtered.filter((d) => {
          return d.emi_amount > search.minAmount;
        });
      }
      if (search.maxAmount) {
        filtered = filtered.filter((d) => {
          return d.emi_amount < search.maxAmount;
        });
      }
      if (search.startDate) {
        filtered = filtered.filter((d) => {
          return new Date(d.due_date) >= new Date(search.startDate);
        });
      }
      if (search.endDate) {
        filtered = filtered.filter((d) => {
          return new Date(d.due_date) <= new Date(search.endDate);
        });
      }
      setData(filtered);
    }
  };

  useEffect(() => {
    getAllDueDateCollections(search);
  }, [search]);

  const viewCollectionData = (data) => {
    navigate(`/loan-collection?id=${data.id}&loan_id=${data.loan_id}`);
  };

  const editCollectionData = (data) => {
    navigate(`loan-collection?id=${data.id}&loan_id=${data.loan_id}&edit=1`);
  };

  const sendMail = async (data) => {
    await personalLoanSendMail({
      loan_emi_id: data.id,
      token: adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
    });
  };

  const createRows = (data) => {
    const headCells = [
      {
        id: "id",
        label: "Id",
      },
      {
        id: "loan_id",
        label: "Loan ID",
      },
      {
        id: "emi_amount",
        label: "EMI Amount",
      },
      {
        id: "due_date",
        label: "Due Date",
      },
      {
        id: "send_mail",
        label: "",
        type: "button",
        noSort: true,
        icon: EmailIcon,
        iconStyle: { color: "blue" },
        noDisplay: hideDelete,
        callback: sendMail,
      },
      {
        id: "edit",
        label: "",
        type: "button",
        noSort: true,
        icon: EditIcon,
        iconStyle: { color: "blue" },
        noDisplay: hideDelete,
        callback: editCollectionData,
      },
    ];
    function createData(id, loan_id, emi_amount, due_date, email, link) {
      return {
        id,
        loan_id,
        emi_amount,
        due_date,
        email,
        link,
      };
    }

    const rows = data.map((value) => {
      return createData(
        value.id,
        value.loan_id,
        value.emi_amount,
        new Date(value.due_date).toLocaleDateString("en-GB"),
        value?.loan?.customer?.email,
        `/loan-collection?id=${value.id}&loan_id=${value.loan_id}`
      );
    });

    const filters = (
      <>
        <TableRow hover tabIndex={-1}>
          <TableCell>
            {clear ? (
              <Tooltip title="Clear Filters" arrow>
                <IconButton onClick={clearFilter}>
                  <CancelIcon className="primary-text" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              variant="outlined"
              name="loan_id"
              value={search.loan_id}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <TextField
              style={{ width: "45%" }}
              variant="outlined"
              type="number"
              name="minAmount"
              value={search.minAmount}
              onChange={onChangeData}
            />
            <span style={{ marginLeft: "4%", marginRight: "4%" }}>-</span>
            <TextField
              style={{ width: "45%" }}
              variant="outlined"
              type="number"
              name="maxAmount"
              value={search.maxAmount}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <input
              style={{ width: "45%" }}
              type="date"
              name="startDate"
              max={new Date().toISOString().split("T")[0]}
              value={search.startDate && search.startDate.split("T")[0]}
              onChange={onChangeData}
            />
            <span style={{ marginLeft: "4%", marginRight: "4%" }}>-</span>
            <input
              style={{ width: "45%" }}
              type="date"
              name="endDate"
              value={search.endDate && search.endDate.split("T")[0]}
              min={search.startDate && search.startDate}
              max={new Date().toISOString().split("T")[0]}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell style={{ width: "34px" }}></TableCell>
          <TableCell style={{ width: "34px" }}></TableCell>
        </TableRow>
      </>
    );

    return { headCells, rows, filters };
  };

  function clearFilter() {
    setSearch({
      id: "",
      loan_id: "",
      minAmount: 0,
      maxAmount: 0,
      startDate: 0,
      endDate: 0,
    });
  }

  function onChangeData(e) {
    if (e.target.name === "startDate" && search.endDate !== null) {
      setSearch((search) => ({
        ...search,
        endDate: null,
      }));
    }
    if (e.target.name === "endDate" && search.startDate === null) {
      setSearch((search) => ({
        ...search,
        startDate: e.target.value,
      }));
    }
    setSearch((search) => ({
      ...search,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    let flag = 0;
    Object.keys(search).forEach(function (key) {
      if (search[key] !== "") {
        flag = 1;
      }
    });
    flag === 0 ? setClear(false) : setClear(true);
  }, [search]);

  useEffect(() => {
    let f_data = [];
    if (data.length > 0) {
      data.map((d) => {
        f_data.push({
          id: d.id,
          loan_id: d.loan_id,
          emi_amount: d.emi_amount,
          due_date: d.due_date,
        });
      });
    }
    setDownloadData(f_data);
  }, [data]);

  const handleExportRows = () => {
    if (downloadData.length > 0) {
      const doc = new jsPDF();
      const tableData = downloadData.map((row) => Object.values(row));
      const tableHeaders = Object.keys(downloadData[0]);

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });

      doc.save("loan_collections.pdf");
    } else {
      Swal.fire({
        title: "Caution",
        text: "You can not download 0 records.",
        icon: "warning",
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Grid>
            <CSVLink
              data={downloadData}
              filename={"loan_collections.csv"}
              className="btn secondary-btn mr-1"
              target="_blank"
            >
              <DownloadIcon /> CSV
            </CSVLink>
            <Button
              onClick={handleExportRows}
              className="btn secondary-btn"
              variant="outlined"
            >
              <DownloadIcon /> PDF
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box component={Paper} sx={{ pt: 2 }}>
            {/* {data.length > 0 ? */}
            <CustomTable {...createRows(data)} hideDelete={true} />
            {/* :
                            <Box sx={{ p: 3 }}><h5>You have not added any admin.</h5></Box>
                        } */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default LoanCollections;
