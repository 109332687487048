import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import CustomerData from "../Components/customerData";
import VerifiedIcon from "@mui/icons-material/Verified";
import { getAdmin } from "../api/admin";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { validateSalary, validateSelect } from "../Common/validations";
import { customers, getCustomer } from "../api/customer";
import {
  getBankPersonalLoan,
  readBankPersonalLoan,
  updateBankPersonalLoanData,
  addBankPersonalLoanData,
} from "../api/bankPersonalLoan";
function BankPersonalLoan() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const edit = new URLSearchParams(search).get("edit");

  const [adminData, setAdminData] = useState({});
  const [customersData, setCustomersData] = useState([]);
  const [selectedCust, setSelectedCust] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  const findOptionById = (options, id) => {
    return options.find((option) => option.id == id);
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          if (id) {
            const res = await getBankPersonalLoan({
              loan_id: id,
              token: response.data.accessToken,
            });
            if (res?.status === 200) {
              setData(res.data);
              setSelectedStatus(res.data.status);
            }
            if (!data.read_time || !data.admin_id) {
              const res1 = await readBankPersonalLoan({
                token: response.data.accessToken,
                data: {
                  id: id,
                  admin_id: response.data.id,
                },
              });
            }
          } else {
            const cust = await customers({
              token:
                checkCookie?.accessToken ||
                getLocalStorage("rv-admin").accessToken,
            });
            if (cust?.status === 200) {
              setCustomersData(cust.data);
            }
          }
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  function validateBankName(str) {
    setErrors((errors) => ({
      ...errors,
      bank_name: validateSelect(str).error,
    }));
  }
  function validateLoanStatus(str) {
    setErrors((errors) => ({
      ...errors,
      status: validateSelect(str).error,
    }));
  }
  function validateCustomer(str) {
    setErrors((errors) => ({
      ...errors,
      customer_id: validateSelect(str).error,
    }));
  }
  function validateAmount(str) {
    setErrors((errors) => ({
      ...errors,
      amount: validateSalary(str).error,
    }));
  }

  function validateAddForm() {
    validateLoanStatus(selectedStatus);
    validateBankName(data.bank_name);
    validateAmount(data.amount);
    if (!id) {
      validateCustomer(selectedCust);
    }

    let valid =
      validateSelect(selectedStatus).valid &&
      validateSelect(data.bank_name).valid &&
      validateSalary(data.amount).valid &&
      (!id ? validateSelect(selectedCust).valid : true);
    return valid;
  }

  function validateUpdateForm() {
    validateLoanStatus(selectedStatus);
    let valid = validateSelect(selectedStatus).valid;
    return valid;
  }

  const addData = async (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      if (selectedCust) {
        const cust = await getCustomer({
          customer_id: selectedCust,
          token:
            adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
        });
        const response = await addBankPersonalLoanData({
          data: {
            name: cust.data.name,
            email: cust.data.email,
            phone: cust.data.phone,
            status: selectedStatus ? selectedStatus : "Requested",
            bank_name: data.bank_name,
            amount: data.amount,
            reference_id: data.reference_id,
          },
          token:
            adminData.accessToken || getLocalStorage("rv-admin")?.accessToken,
        });
        if (response?.status === 200) {
          if (id) {
            navigate(`/bank-personal-loan?id=${id}`);
          } else {
            navigate("/bank-personal-loans");
          }
        } else {
          Swal.fire("Oops!", "Something went wrong...", "error");
        }
      }
    }
  };
  const updateData = async (e) => {
    e.preventDefault();
    if (validateUpdateForm()) {
      const response = await updateBankPersonalLoanData({
        data: {
          loan_id: id,
          status: selectedStatus ? selectedStatus : "Requested",
          amount: data.amount,
          reference_id: data.reference_id,
          admin_id: adminData.id || getLocalStorage("rv-admin")?.id,
        },
        token:
          adminData.accessToken || getLocalStorage("rv-admin")?.accessToken,
      });
      if (response?.status === 200) {
        if (id) {
          navigate(`/bank-personal-loan?id=${id}`);
        } else {
          navigate("/bank-personal-loans");
        }
      } else {
        Swal.fire("Oops!", "Something went wrong...", "error");
      }
    }
  };

  const bankNames = [
    {
      id: "IndusInd Personal Loan",
      name: "IndusInd Personal Loan",
    },
    {
      id: "Aditya Birla Personal Loan",
      name: "Aditya Birla Personal Loan",
    },
    {
      id: "SMFC Personal Loan",
      name: "SMFC Personal Loan",
    },
    {
      id: "Credit Vidya Personal Loan",
      name: "Credit Vidya Personal Loan",
    },
    {
      id: "Fibe Personal Loan",
      name: "Fibe Personal Loan",
    },
    {
      id: "Bajaj Personal Loan",
      name: "Bajaj Personal Loan",
    },
    {
      id: "IIFL Business Loan",
      name: "IIFL Business Loan",
    },
    {
      id: "Aditya Birla Udyog Business Loan",
      name: "Aditya Birla Udyog Business Loan",
    },
  ];

  return (
    <>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Link className="text-decoration-none" to="/bank-personal-loans">
            <Button className="primary-btn" variant="contained">
              {" "}
              Back
            </Button>
          </Link>
          {id && !edit && (
            <Link
              className="text-decoration-none"
              to={`/bank-personal-loan?id=${id}&edit=1`}
            >
              <Button className="primary-btn" variant="contained">
                {" "}
                Edit
              </Button>
            </Link>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box component={Paper} sx={{ overflow: "auto" }}>
            <Box
              sx={{
                width: "100%",
                display: "table",
                tableLayout: "fixed",
                p: 2,
              }}
            >
              <Table>
                {!(!id && edit) && (
                  <>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Name</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.name}</>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>
                        Contact No.
                      </TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>
                          {data?.customer?.phone}{" "}
                          {data?.customer?.phone_verified ? (
                            <VerifiedIcon />
                          ) : null}
                        </>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Email</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.email}</>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {!id && edit && (
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Customer</TableCell>
                    <TableCell style={{ width: "80%" }}>
                      <Autocomplete
                        value={
                          findOptionById(customersData, selectedCust) || null
                        }
                        onChange={(event, newValue) => {
                          setSelectedCust(newValue ? newValue.id : null);
                        }}
                        onBlur={() => validateCustomer(selectedCust)}
                        options={
                          !customersData
                            ? [{ label: "Loading...", id: 0 }]
                            : customersData
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        renderOption={(props, option) => (
                          <li {...props}>{option.name}</li>
                        )}
                      />
                      {errors.customer_id && edit && (
                        <Typography className="error-text">
                          {errors.customer_id}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Bank Name</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit && !id ? (
                      <Select
                        value={data.bank_name}
                        name="bank_name"
                        onChange={onChangeData}
                        onBlur={() => validateBankName(data.bank_name)}
                      >
                        {bankNames.map((option, i) => (
                          <MenuItem key={i} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <b>{data.bank_name}</b>
                    )}
                    {errors.bank_name && edit && (
                      <Typography className="error-text">
                        {errors.bank_name}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Loan Status</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <Select
                        value={selectedStatus}
                        name="status"
                        onChange={(e) => setSelectedStatus(e.target.value)}
                        onBlur={() => validateLoanStatus(selectedStatus)}
                      >
                        {[
                          { id: "Requested", name: "Requested" },
                          { id: "Processing", name: "Processing" },
                          { id: "Approved", name: "Approved" },
                          { id: "Rejected", name: "Rejected" },
                        ].map((option, i) => (
                          <MenuItem key={i} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <b>{selectedStatus}</b>
                    )}
                    {errors.status && edit && (
                      <Typography className="error-text">
                        {errors.status}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Amount</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="amount"
                        value={data.amount}
                        onChange={onChangeData}
                        onBlur={() => validateAmount(data.amount)}
                      />
                    ) : (
                      <p>{data.amount}</p>
                    )}
                    {errors.amount && edit && (
                      <Typography className="error-text">
                        {errors.amount}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Ref ID</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="reference_id"
                        value={data.reference_id}
                        onChange={onChangeData}
                      />
                    ) : (
                      <p>{data.reference_id}</p>
                    )}
                    {errors.reference_id && edit && (
                      <Typography className="error-text">
                        {errors.reference_id}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </Table>
            </Box>
          </Box>
        </Grid>
        <Box component="form" sx={{ ml: 3 }}>
          {id && edit && (
            <>
              <Button
                sx={{ mt: 3 }}
                onClick={updateData}
                variant="contained"
                className="primary-btn me-3"
              >
                {" "}
                Update{" "}
              </Button>
              <Link
                className="text-decoration-none"
                to={`/bank-personal-loan?id=${id}`}
              >
                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  className="primary-btn"
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </Link>
            </>
          )}
          {!id && edit && (
            <Button
              sx={{ mt: 3 }}
              onClick={addData}
              variant="contained"
              className="primary-btn"
            >
              {" "}
              Save
            </Button>
          )}
        </Box>
      </Grid>
      {id && (
        <CustomerData
          adminData={adminData}
          id={data?.customer_id}
          edit={null}
          noAccordian
        />
      )}
    </>
  );
}
export default BankPersonalLoan;
