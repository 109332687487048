import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import CustomerData from "../Components/customerData";
import VerifiedIcon from "@mui/icons-material/Verified";
import { getAdmin } from "../api/admin";
import user from "../Assets/Image/user.png";

import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  validateDate,
  validateMax,
  validateSalary,
  validateSelect,
  validateText,
} from "../Common/validations";
import { customers } from "../api/customer";
import {
  addPersonalLoanData,
  getPersonalLoan,
  rejectPersonalLoan,
  sendEmailPersonalLoan,
  updatePersonalLoanData,
  updatePersonalLoanStatus,
} from "../api/personalLoan";
import path from "path-browserify";
function PersonalLoan() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const edit = new URLSearchParams(search).get("edit");

  const [adminData, setAdminData] = useState({});
  const [customersData, setCustomersData] = useState([]);
  const [selectedCust, setSelectedCust] = useState(null);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [editable, setEditable] = useState(false);
  const [cusSatus, setCusStatus] = useState({
    admin_id: null,
    admin_name: "",
  });
  const [selfieStatus, setSelfieStatus] = useState({
    admin_id: null,
    admin_name: "",
  });

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value.toUpperCase(),
    }));
  }

  const findOptionById = (options, id) => {
    return options.find((option) => option.id == id);
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          if (id) {
            await getPLdata(id, response.data);
          } else {
            const cust = await customers({
              token:
                checkCookie?.accessToken ||
                getLocalStorage("rv-admin").accessToken,
            });
            if (cust?.status === 200) {
              setCustomersData(cust.data);
            }
          }
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  const getPLdata = async (id, admin) => {
    const res = await getPersonalLoan({
      loan_id: id,
      token: admin.accessToken,
    });
    if (res?.status === 200) {
      if (!res.data.offered_amount) {
        res.data.offered_tenure = getNext7DayDate();
      }
      setData(res.data);
      const addItem = res.data.status?.find(
        (item) => item.name === "Disbursed"
      );
      setCusStatus(addItem);
      const selfieItem = res.data.status?.find(
        (item) => item.name === "Selfie Upload"
      );
      setSelfieStatus(selfieItem);
      const req_admin_id = res.data.status.find(
        (item) => item.name === "Requested"
      );
      if (!req_admin_id.admin_id) {
        await updatePersonalLoanStatus({
          data: {
            loan_id: id,
            status_name: "Requested",
            admin_id: admin.id,
            admin_name: `${admin.f_name} ${admin.l_name}`,
            approved: 1,
          },
          token: admin.accessToken,
        });
      }
      // const counter_added = res.data.status.find(
      //   (item) => item.name === "Counter Offer"
      // );
      // if (res.data.status_name === "Counter Offer" || counter_added.added) {
      const statusNames = [
        "Counter Offer",
        "Selfie Upload",
        "e-KYC",
        "Bank Details Verification",
        "Upload Bank Statement",
        "e-Sign",
        "Disbursement",
        "Disbursed",
      ];
      if (statusNames.includes(res.data.status_name)) {
        setEditable(true);
      }
    }
  };

  useEffect(() => {
    const offered_interest = data.offered_interest;
    const offered_tenure = data.offered_tenure;
    const offered_amount = data.offered_amount;
    if (data.offered_amount > 5000) {
      if (offered_amount && offered_interest && offered_tenure) {
        const monthlyInterestRate = offered_interest
          ? offered_interest / 12 / 100
          : 0;
        const totalPayments = offered_tenure;

        if (monthlyInterestRate === 0) {
          const EMI = offered_amount / totalPayments;
          setData((data) => ({
            ...data,
            total_payable_amount: Number(offered_amount).toFixed(0),
            emi_amount: Number(EMI).toFixed(0),
          }));
        } else {
          const EMI =
            (offered_amount *
              monthlyInterestRate *
              Math.pow(1 + monthlyInterestRate, totalPayments)) /
            (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);
          // setEmi(EMI);

          // let scheduleHTML = '<table>';
          // scheduleHTML += '<tr><th>Month</th><th>Payment</th><th>Principal</th><th>Interest</th><th>Balance</th></tr>';

          let balance = offered_amount;
          let totalInterest = 0;
          for (let month = 1; month <= totalPayments; month++) {
            const interest = balance * monthlyInterestRate;
            totalInterest += interest;
            const principal = EMI - interest;
            balance -= principal;
            //     scheduleHTML += `<tr><td>${month}</td><td>${EMI.toFixed(0)}</td><td>${principal.toFixed(0)}</td><td>${interest.toFixed(0)}</td><td>${balance.toFixed(0)}</td></tr>`;
          }
          // scheduleHTML += '</table>';
          // setSchedule(scheduleHTML)
          // setTotalInterestAmount(totalInterest)
          const t =
            Number(totalInterest) +
            (offered_amount ? Number(offered_amount) : 0);
          setData((data) => ({
            ...data,
            total_payable_amount: t.toFixed(0),
            emi_amount: EMI.toFixed(0),
          }));
        }
      } else {
        setData((data) => ({
          ...data,
          total_payable_amount: 0,
        }));
      }
    } else {
      setData((data) => ({
        ...data,
        total_payable_amount:
          Number(data.offered_amount ? data.offered_amount : 0) +
          Number(data.offered_interest ? data.offered_interest : 0),
      }));
    }
  }, [data.offered_amount, data.offered_tenure, data.offered_interest]);

  function validateOfferedAmount(str) {
    setErrors((errors) => ({
      ...errors,
      offered_amount: validateSalary(str).error,
    }));
  }
  function validateCustomer(str) {
    setErrors((errors) => ({
      ...errors,
      customer_id: validateSelect(str).error,
    }));
  }
  function validateOfferedTenure(str) {
    setErrors((errors) => ({
      ...errors,
      offered_tenure:
        data.offered_amount > 5000
          ? validateSalary(str).error
          : validateDate(str).error,
    }));
  }
  function validateOfferedInterest(str) {
    setErrors((errors) => ({
      ...errors,
      offered_interest: validateSalary(str).error,
    }));
  }
  function validateTotalPayable(offered_amount, total_payable_amount) {
    setErrors((errors) => ({
      ...errors,
      total_payable_amount: validateMax(offered_amount, total_payable_amount)
        .error,
    }));
  }
  function validateProcessingFee(str) {
    setErrors((errors) => ({
      ...errors,
      processing_fee: validateSalary(str).error,
    }));
  }
  function validateReqAmount(str) {
    setErrors((errors) => ({
      ...errors,
      req_amount: validateSalary(str).error,
    }));
  }

  function validateUpdateForm() {
    validateOfferedAmount(data.offered_amount);
    validateOfferedTenure(data.offered_tenure);
    validateOfferedInterest(data.offered_interest);
    validateTotalPayable(data.offered_amount, data.total_payable_amount);
    validateProcessingFee(data.processing_fee);
    if (!id) {
      validateCustomer();
    }

    let valid =
      validateSalary(data.offered_amount).valid &&
      (data.offered_amount > 5000
        ? validateSalary(data.offered_tenure).valid
        : validateDate(data.offered_tenure).valid) &&
      validateSalary(data.offered_interest).valid &&
      validateMax(data.offered_amount, data.total_payable_amount).valid &&
      validateSalary(data.processing_fee).valid;
    return valid;
  }

  function validateAddForm() {
    validateCustomer();
    validateReqAmount();

    let valid =
      validateSelect(selectedCust).valid &&
      validateSalary(data.req_amount).valid;
    return valid;
  }

  const updateData = async () => {
    if (validateUpdateForm()) {
      const response = await updatePersonalLoanData({
        data: {
          id: id,
          purpose: data.purpose,
          offered_amount: data.offered_amount,
          offered_interest: data.offered_interest ? data.offered_interest : 0,
          offered_tenure: data.offered_tenure
            ? data.offered_tenure
            : getNext7DayDate(),
          processing_fee: data.processing_fee ? data.processing_fee : 0,
          total_payable_amount: data.total_payable_amount,
          emi: data.offered_amount > 5000 ? 1 : 0,
          emi_amount: data.emi_amount ? data.emi_amount : 0,
          loan_verified: 1,
          admin_id: adminData.id,
        },
        token:
          adminData.accessToken || getLocalStorage("rv-admin")?.accessToken,
      });

      if (response?.status === 200) {
        if (id) {
          await updatePersonalLoanStatus({
            data: {
              loan_id: id,
              status_name: "Counter Offer",
              admin_id: adminData.id,
              admin_name: `${adminData.f_name} ${adminData.l_name}`,
              approved: 1,
            },
            token: adminData.accessToken,
          });
          await getPLdata(id, adminData);
          navigate(`/personal-loan?id=${id}`);
        } else {
          navigate("/personal-loans");
        }
      } else {
        Swal.fire("Oops!", response.message, "error");
      }
    }
  };

  const addData = async (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      const response = await addPersonalLoanData({
        data: {
          customer_id: selectedCust,
          purpose: data.purpose,
          req_amount: data.req_amount,
        },
        token:
          adminData.accessToken || getLocalStorage("rv-admin")?.accessToken,
      });
      if (response?.status === 200) {
        if (id) {
          navigate(`/personal-loan?id=${id}`);
        } else {
          navigate("/personal-loans");
        }
      } else {
        Swal.fire("Oops!", response?.message || "", "error");
      }
    }
  };

  const rejectLoan = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject it!",
      input: "text", // Type of input (could also be 'email', 'password', etc.)
      inputPlaceholder: "Reason...",
      inputValidator: (value) => {
        if (!value) {
          return "Need to add reason something!";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const inputValue = result.value;
        await rejectPersonalLoan({
          token:
            adminData.accessToken || getLocalStorage("rv-admin")?.accessToken,
          data: {
            loan_id: id,
            rejected_reason: inputValue,
            admin_id: adminData.id || getLocalStorage("rv-admin")?.id,
          },
        });
      }
    });
  };

  const handleChange = async (e) => {
    await updatePersonalLoanStatus({
      data: {
        loan_id: id,
        status_name: "Disbursement",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        approved: 1,
      },
      token: adminData.accessToken,
    });
    await updatePersonalLoanStatus({
      data: {
        loan_id: id,
        status_name: "Disbursed",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        approved: 1,
      },
      token: adminData.accessToken,
    });

    await getPLdata(id, adminData);
  };
  const handleSelfieChange = async (e) => {
    await updatePersonalLoanStatus({
      data: {
        loan_id: id,
        status_name: "Selfie Upload",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        approved: 1,
      },
      token: adminData.accessToken,
    });
    await getPLdata(id, adminData);
  };
async function sendEmail() {
    await sendEmailPersonalLoan({
      data: {
        loan_id: id,
        email: data.email,
        status_name: "Selfie Upload",
        extra_info: "",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        link: `${process.env.REACT_APP_FE}/apply-now/Instant/selfie?id=${id}`
      },
      token: adminData.accessToken,
    });
    await getPLdata(id, adminData);
  }  
  const getNext7DayDate = () => {
    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 7);
    return futureDate.toISOString();
  };

  return (
    <>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Link className="text-decoration-none" to="/personal-loans">
            <Button className="primary-btn" variant="contained">
              {" "}
              Back
            </Button>
          </Link>
          {id &&
            data.status_name !== "Disbursed" &&
            data.status_name !== "Rejected" && (
              <Button
                className="primary-btn"
                variant="contained"
                onClick={rejectLoan}
              >
                Reject
              </Button>
            )}
          {id && !edit && data.status_name === "Counter Offer" && (
            <Link
              className="text-decoration-none"
              to={`/personal-loan?id=${id}&edit=1`}
            >
              <Button className="primary-btn ms-3" variant="contained">
                {" "}
                Edit
              </Button>
            </Link>
          )}
        </Grid>

        <Box component={Paper} sx={{ overflow: "auto" }}>
          <Grid container spacing={2}>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "table",
                  tableLayout: "fixed",
                  p: 2,
                }}
              >
                <Table>
                  {!(!id && edit) && (
                    <>
                      <TableRow>
                        <TableCell style={{ width: "20%" }}>Name</TableCell>
                        <TableCell style={{ width: "80%" }}>
                          <>{data?.customer?.name}</>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: "20%" }}>
                          Contact No.
                        </TableCell>
                        <TableCell style={{ width: "80%" }}>
                          <>
                            {data?.customer?.phone}{" "}
                            {data?.customer?.phone_verified ? (
                              <VerifiedIcon />
                            ) : null}
                          </>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: "20%" }}>Email</TableCell>
                        <TableCell style={{ width: "80%" }}>
                          <>{data?.customer?.email}</>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {!id && edit && (
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Customer</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <Autocomplete
                          value={
                            findOptionById(customersData, selectedCust) || null
                          } // Ensure value is not null
                          onChange={(event, newValue) => {
                            setSelectedCust(newValue ? newValue.id : null);
                          }}
                          onBlur={() => validateCustomer(selectedCust)}
                          options={
                            !customersData
                              ? [{ label: "Loading...", id: 0 }]
                              : customersData
                          }
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} />}
                          renderOption={(props, option) => (
                            <li {...props}>{option.name}</li>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {id && (
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Status</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <b>{data.status_name}</b>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Purpose</TableCell>
                    <TableCell style={{ width: "80%" }}>
                      {(edit && !id) ||
                      (edit && id && data.status_name === "Counter Offer") ? (
                        <TextField
                          name="purpose"
                          value={data.purpose}
                          onChange={onChangeData}
                          multiline
                          // rows={2}
                          // maxRows={4}
                        />
                      ) : (
                        <p>{data.purpose}</p>
                      )}
                      {errors.purpose && edit && (
                        <Typography className="error-text">
                          {errors.purpose}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                  {data?.location?.lat && data?.location?.lon && (
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Location</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <p>
                          {data.location?.lat} lat, {data?.location?.lon} lon
                        </p>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>
                      Requested Amount
                    </TableCell>
                    <TableCell style={{ width: "80%" }}>
                      {edit && !id ? (
                        <TextField
                          name="req_amount"
                          value={data.req_amount}
                          onChange={onChangeData}
                          onBlur={() => validateReqAmount(data.req_amount)}
                        />
                      ) : (
                        <b>{data.req_amount}</b>
                      )}
                      {errors.req_amount && edit && (
                        <Typography className="error-text">
                          {errors.req_amount}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>

                  {editable && (
                    <>
                      {id && (
                        <>
                          <TableRow>
                            <TableCell style={{ width: "20%" }}>
                              Offered Amount
                            </TableCell>
                            <TableCell style={{ width: "80%" }}>
                              {edit &&
                              id &&
                              data.status_name === "Counter Offer" ? (
                                <TextField
                                  name="offered_amount"
                                  value={data.offered_amount}
                                  onChange={onChangeData}
                                  onBlur={() =>
                                    validateOfferedAmount(data.offered_amount)
                                  }
                                />
                              ) : (
                                <b>{data.offered_amount}</b>
                              )}
                              {errors.offered_amount && edit && (
                                <Typography className="error-text">
                                  {errors.offered_amount}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: "20%" }}>
                              Offered Tenure{" "}
                              {data.offered_amount > 5000 && <>(in months)</>}
                            </TableCell>
                            <TableCell style={{ width: "80%" }}>
                              {data.offered_amount > 5000 ? (
                                edit &&
                                id &&
                                data.status_name === "Counter Offer" ? (
                                  <TextField
                                    name="offered_tenure"
                                    value={
                                      data.offered_tenure
                                        ? data.offered_tenure
                                        : "12"
                                    }
                                    onChange={onChangeData}
                                    onBlur={() =>
                                      validateOfferedTenure(data.offered_tenure)
                                    }
                                  />
                                ) : (
                                  <p>{data.offered_tenure} months</p>
                                )
                              ) : edit &&
                                id &&
                                data.status_name === "Counter Offer" ? (
                                <input
                                  type="date"
                                  name="offered_tenure"
                                  value={
                                    data.offered_tenure
                                      ? data.offered_tenure.split("T")[0]
                                      : getNext7DayDate().split("T")[0]
                                  }
                                  onChange={onChangeData}
                                  onBlur={() =>
                                    validateOfferedTenure(data.offered_tenure)
                                  }
                                />
                              ) : data?.offered_tenure ? (
                                new Date(
                                  data.offered_tenure
                                ).toLocaleDateString("en-GB")
                              ) : null}
                              {errors.offered_tenure && edit && (
                                <Typography className="error-text">
                                  {errors.offered_tenure}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: "20%" }}>
                              <p>
                                Offered Interest{" "}
                                {data.offered_amount > 5000
                                  ? "(in %)"
                                  : "(in ₹)"}
                              </p>
                            </TableCell>
                            <TableCell style={{ width: "80%" }}>
                              {edit &&
                              id &&
                              data.status_name === "Counter Offer" ? (
                                <TextField
                                  name="offered_interest"
                                  value={data.offered_interest}
                                  onChange={onChangeData}
                                  onBlur={() =>
                                    validateOfferedInterest(
                                      data.offered_interest
                                    )
                                  }
                                />
                              ) : (
                                <b>{data.offered_interest}</b>
                              )}
                              {errors.offered_interest && edit && (
                                <Typography className="error-text">
                                  {errors.offered_interest}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {data.offered_amount > 5000 && (
                        <>
                          {/* <TableRow>
                        <TableCell style={{ width: "20%" }}>EMI</TableCell>
                        <TableCell style={{ width: "80%" }}>
                          {edit && id ? (
                            <RadioGroup
                              row
                              aria-labelledby="emi"
                              name="emi"
                              value={data.emi}
                              onChange={onChangeData}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          ) : data.emi === 1 || data.emi === "1" ? (
                            "Yes"
                          ) : (
                            "No"
                          )}
                          {errors.emi && edit && (
                            <Typography className="error-text">
                              {errors.emi}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow> */}
                          <TableRow>
                            <TableCell style={{ width: "20%" }}>
                              EMI Amount
                            </TableCell>
                            <TableCell style={{ width: "80%" }}>
                              {<b>{data.emi_amount ? data.emi_amount : 0}</b>}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      <TableRow>
                        <TableCell style={{ width: "20%" }}>
                          Processing Fee
                        </TableCell>
                        <TableCell style={{ width: "80%" }}>
                          {edit &&
                          id &&
                          data.status_name === "Counter Offer" ? (
                            <TextField
                              name="processing_fee"
                              value={data.processing_fee}
                              onChange={onChangeData}
                              onBlur={() =>
                                validateProcessingFee(data.processing_fee)
                              }
                            />
                          ) : (
                            <b>
                              {data.processing_fee ? data.processing_fee : "0"}
                            </b>
                          )}
                          {errors.processing_fee && edit && (
                            <Typography className="error-text">
                              {errors.processing_fee}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: "20%" }}>
                          Total Payable Amount
                        </TableCell>
                        <TableCell style={{ width: "80%" }}>
                          <b>
                            {data.total_payable_amount
                              ? data.total_payable_amount
                              : "0"}
                          </b>
                        </TableCell>
                      </TableRow>
                      {data.statement && (
                        <TableRow>
                          <TableCell style={{ width: "20%" }}>
                            Statement
                          </TableCell>
                          <TableCell style={{ width: "80%" }}>
                            <b
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                window.open(
                                  `${process.env.REACT_APP_API}/uploads/Loan/Statement/${data.statement}`,
                                  // path.join(
                                  //   process.env.REACT_APP_API,
                                  //   "uploads",
                                  //   "Loan",
                                  //   "Statement",
                                  //   data.statement
                                  // ),
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                            >
                              View Statement
                            </b>
                          </TableCell>
                        </TableRow>
                      )}
                      {data.agreement && (
                        <TableRow>
                          <TableCell style={{ width: "20%" }}>
                            Agreement
                          </TableCell>
                          <TableCell style={{ width: "80%" }}>
                            <b
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                window.open(
                                  `${process.env.REACT_APP_API}/uploads/Loan/Agreement/${data.agreement}`,
                                  // path.join(
                                  //   process.env.REACT_APP_API,
                                  //   "uploads",
                                  //   "Loan",
                                  //   "Agreement",
                                  //   data.agreement
                                  // ),
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                            >
                              View Agreement
                            </b>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </Table>

                {(data.status_name !== "Disbursed" ||
                  data.status_name !== "Rejected") &&
                  id && (
                    <div>
                      <span style={{ marginLeft: "5px", marginTop: "6px" }}>
                        Transfered{" "}
                        <b>
                          {Number(data.offered_amount) - data.processing_fee}
                        </b>{" "}
                        Amount:
                      </span>
                      <Switch
                        style={{ color: "#002d5b" }}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={!(data.status_name === "Disbursement" && id)}
                      />
                    </div>
                  )}
                {data.status_name === "Disbursed" && id && (
                  <>
                    <p
                      style={{
                        marginLeft: "5px",
                        fontSize: "13px",
                        fontStyle: "italic",
                      }}
                    >
                      Amount{" "}
                      <b>{Number(data.offered_amount) - data.processing_fee}</b>{" "}
                      transfered by <b>{cusSatus.admin_name}</b>
                    </p>
                  </>
                )}
              </Box>
              <Box component="form" sx={{ ml: 3 }}>
                {id && edit && data.status_name === "Counter Offer" && (
                  <>
                    <Button
                      sx={{ mt: 3 }}
                      onClick={updateData}
                      variant="contained"
                      className="primary-btn me-3"
                    >
                      {" "}
                      Update{" "}
                    </Button>
                    <Link
                      className="text-decoration-none"
                      to={`/personal-loan?id=${id}`}
                    >
                      <Button
                        sx={{ mt: 3 }}
                        variant="contained"
                        className="primary-btn"
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                    </Link>
                  </>
                )}
                {!id && edit && (
                  <Button
                    sx={{ mt: 3 }}
                    onClick={addData}
                    variant="contained"
                    className="primary-btn"
                  >
                    {" "}
                    Save
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box
                component={Paper}
                className="image-preview"
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  height: "auto",
                  maxHeight: "380px",
                  position: "relative",
                  overflow: "hidden",
                  display: "inline-block",
                  marginTop: "30px",
                  marginRight: "30px",
                }}
              >
                {data.selfie ? (
                  <img
                    // src={`${path.join(
                    //   process.env.REACT_APP_API,
                    //   "uploads",
                    //   "Customer",
                    //   data.profile
                    // )}`}
                    src={`${process.env.REACT_APP_API}/uploads/Loan/Selfie/${data.selfie}`}
                    alt="Preview"
                    style={{ width: "100%", height: "100%" }}
                  />                 
                ) : (
                  <img
                    src={user}
                    alt="Preview"
                    style={{ width: "100%", height: "100%" }}
                  />
                )}
                 {selfieStatus?.admin_name && selfieStatus?.approved === 1 ? (
                    <p
                      style={{
                        marginLeft: "5px",
                        fontSize: "13px",
                        fontStyle: "italic",
                      }}
                    >
                      Details verified by <b>{selfieStatus.admin_name}</b>
                    </p>
                  ) : (
                    <>
                      <p style={{ marginLeft: "5px", marginTop: "6px" }}>
                        Verify details:
                      </p>
                      <Switch
                        style={{ color: "#002d5b" }}
                        onChange={handleSelfieChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Button
                        sx={{ ml: 2 }}
                        className="primary-btn"
                        variant="contained"
                        onClick={sendEmail}
                      >
                        Send mail
                      </Button>
                    </>
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {id && (
        <CustomerData
          adminData={adminData}
          id={data?.customer_id}
          edit={null}
          noAccordian
          loanData={data}
          setLoanData={setData}
          getPLdata={(id, adminData) => getPLdata(id, adminData)}
        />
      )}
    </>
  );
}
export default PersonalLoan;
