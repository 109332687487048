import {
  AccordionDetails,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  validateEmail,
  validatePincode,
  validateSalary,
  validateSelect,
  validateText,
} from "../Common/validations";
import { professionalDetails, verifyOccupation } from "../api/customerAuth";
import Swal from "sweetalert2";
import { getLocalStorage } from "../Common/cookie";
import { useNavigate } from "react-router-dom";
import { getLocation } from "../api/location";
import {
  sendEmailPersonalLoan,
  updatePersonalLoanStatus,
} from "../api/personalLoan";
function CustOccupation(props) {
  const {
    adminData,
    edit,
    data,
    setData,
    getCustData,
    loanData,
    setLoanData,
    getPLdata,
  } = props;
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [cusSatus, setCusStatus] = useState({
    admin_id: null,
    admin_name: "",
  });
  const [occData, setOccData] = useState({
    income: 0,
    c_name: "",
    c_email: "",
    c_address: "",
    c_pincode: "",
    c_city: "",
    c_state: "",
    c_country: "",
  });
  const [otype, setOtype] = useState(null);
  function onChangeData(e) {
    if (e.target.name === "c_pincode") {
      setLocation(e.target.value);
    }
    setOccData((occData) => ({
      ...occData,
      [e.target.name]: e.target.value.toUpperCase(),
    }));
  }

  useEffect(() => {
    const asyncFn = async () => {
      if (data?.occupation?.[0]) {
        setOccData(data.occupation[0]);
        setOtype(data.occupation[0]?.occupation_type);
        if (data.occupation[0]?.c_pincode) {
          await setLocation(data.occupation[0].c_pincode);
        }
      }
    };
    asyncFn();
  }, [data]);

  useEffect(() => {
    if (loanData?.id) {
      const addItem = loanData.status.find(
        (item) => item.name === "Occupation Verification"
      );
      setCusStatus(addItem);
    }
  }, [loanData]);

  async function handleChange() {
    await updatePersonalLoanStatus({
      data: {
        loan_id: loanData.id,
        status_name: "Occupation Verification",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        approved: 1,
      },
      token: adminData.accessToken,
    });
    await verifyOccupation({
      data: {
        id: occData.id,
        verified: 1,
      },
      token: adminData.accessToken,
    });
    await getCustData();
    await getPLdata(loanData.id, adminData);
  }
  async function sendEmail() {
    await sendEmailPersonalLoan({
      data: {
        loan_id: loanData.id,
        email: data.email,
        status_name: "Occupation Verification",
        extra_info: "",
        admin_id: adminData.id,
        admin_name: `${adminData.f_name} ${adminData.l_name}`,
        link: `${process.env.REACT_APP_FE}/apply-now/Instant/professional?type=${otype}&id=${loanData.id}`
      },
      token: adminData.accessToken,
    });
    await verifyOccupation({
      data: {
        id: occData.id,
        verified: 0,
      },
      token: adminData.accessToken,
    });
    await getCustData();
    await getPLdata(loanData.id, adminData);
  }

  const setLocation = async (c_pincode) => {
    if (c_pincode && c_pincode.length === 6) {
      const location = await getLocation({
        pincode: c_pincode,
      });
      if (location[0]?.Status === "Success") {
        const lData = location[0].PostOffice[0];
        setOccData((occData) => ({
          ...occData,
          c_city: lData.District.toUpperCase(),
          c_state: lData.State.toUpperCase(),
          c_country: lData.Country.toUpperCase(),
        }));
        setErrors((errors) => ({
          ...errors,
          c_pincode: "",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          c_pincode: "Please enter valid Pin Code",
        }));
      }
    }
  };

  function validateType(str) {
    setErrors((errors) => ({
      ...errors,
      occupation_type: validateSelect(str).error,
    }));
  }
  function validateCompanyName(str) {
    setErrors((errors) => ({
      ...errors,
      c_name: validateText(str).error,
    }));
  }
  function validateCompanyEmail(str) {
    setErrors((errors) => ({
      ...errors,
      c_email: validateEmail(str).error,
    }));
  }
  function validateCompanyAddress(str) {
    setErrors((errors) => ({
      ...errors,
      c_address: validateText(str).error,
    }));
  }
  function validateCompanyPincode(str) {
    setErrors((errors) => ({
      ...errors,
      c_pincode: validatePincode(str).error,
    }));
  }
  function validateIncome(str) {
    setErrors((errors) => ({
      ...errors,
      income: validateSalary(str).error,
    }));
  }

  function validateForm() {
    validateType(otype);
    if (otype === "Salaried") {
      validateCompanyName(occData.c_name);
      validateCompanyEmail(occData.c_email);
      validateCompanyAddress(occData.c_address);
      validateCompanyPincode(occData.c_pincode);
      validateIncome(occData.income);
    }
    let valid =
      validateSelect(otype).valid &&
      (otype === "Salaried"
        ? validateText(occData.c_name).valid &&
          validateEmail(occData.c_email).valid &&
          validateText(occData.c_address).valid &&
          validatePincode(occData.c_pincode).valid &&
          validateSalary(occData.income).valid
        : true);
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (!errors.c_pincode) {
      if (validateForm()) {
        const response = await professionalDetails({
          token:
            adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
          data: {
            customer_id: data.id,
            occupation_type: otype,
            income: occData.income,
            c_name: occData.c_name,
            c_email: occData.c_email,
            c_address: occData.c_address,
            c_pincode: occData.c_pincode,
            c_city: occData.c_city,
            c_state: occData.c_state,
            c_country: occData.c_country,
          },
        });
        if (response?.status === 200) {
          Swal.fire(
            "Good Job!",
            "Customer data updated successfully",
            "success"
          );
          getCustData();
        }
      }
    }
  }
  return (
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Type</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <RadioGroup
                    row
                    aria-labelledby="occupation_type"
                    name="occupation_type"
                    value={otype}
                    onChange={(e) => setOtype(e.target.value)}
                  >
                    <FormControlLabel
                      value="Salaried"
                      control={<Radio />}
                      label="Salaried"
                    />
                    <FormControlLabel
                      value="Non Salaried"
                      control={<Radio />}
                      label="Self Employed"
                    />
                  </RadioGroup>
                ) : otype === "Non Salaried" ? (
                  "Self Employed"
                ) : (
                  otype
                )}
                {errors.occupation_type && edit && (
                  <Typography className="error-text">{errors.occupation_type}</Typography>
                )}
              </TableCell>
            </TableRow>
            {otype === "Salaried" ? (
              <>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Income Per Month
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="income"
                        value={occData.income}
                        onChange={onChangeData}
                        onBlur={() => validateIncome(occData.income)}
                      />
                    ) : (
                      occData.income
                    )}
                    {errors.income && edit && (
                      <Typography className="error-text">
                        {errors.income}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization Name
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="c_name"
                        value={occData.c_name}
                        onChange={onChangeData}
                        onBlur={() => validateCompanyName(occData.c_name)}
                      />
                    ) : (
                      occData.c_name
                    )}
                    {errors.c_name && edit && (
                      <Typography className="error-text">
                        {errors.c_name}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization Email
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="c_email"
                        value={occData.c_email}
                        onChange={onChangeData}
                        onBlur={() => validateCompanyEmail(occData.c_email)}
                      />
                    ) : (
                      occData.c_email
                    )}
                    {errors.c_email && edit && (
                      <Typography className="error-text">
                        {errors.c_email}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization Address
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="c_address"
                        value={occData.c_address}
                        onChange={onChangeData}
                        onBlur={() => validateCompanyAddress(occData.c_address)}
                      />
                    ) : (
                      occData.c_address
                    )}
                    {errors.c_address && edit && (
                      <Typography className="error-text">
                        {errors.c_address}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization Pin Code
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="c_pincode"
                        value={occData.c_pincode}
                        onChange={onChangeData}
                        onBlur={() => validateCompanyPincode(occData.c_pincode)}
                      />
                    ) : (
                      occData.c_pincode
                    )}
                    {errors.c_pincode && edit && (
                      <Typography className="error-text">
                        {errors.c_pincode}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization City
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {occData.c_city}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization State
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {occData.c_state}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization Country
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {occData.c_country}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Yearly Revenue</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="income"
                        value={occData.income}
                        onChange={onChangeData}
                        onBlur={() => validateIncome(occData.income)}
                      />
                    ) : (
                      occData.income
                    )}
                    {errors.income && edit && (
                      <Typography className="error-text">
                        {errors.income}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Company Name</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="c_name"
                        value={occData.c_name}
                        onChange={onChangeData}
                        onBlur={() => validateCompanyName(occData.c_name)}
                      />
                    ) : (
                      occData.c_name
                    )}
                    {errors.c_name && edit && (
                      <Typography className="error-text">
                        {errors.c_name}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </>
            )}
          </Table>
          {edit && (
            <Button
              className="primary-btn"
              variant="contained"
              onClick={saveData}
            >
              {" "}
              Save
            </Button>
          )}
          {loanData?.id && (
            <>
              {cusSatus?.admin_name && cusSatus?.approved === 1 ? (
                <p
                  style={{
                    marginLeft: "5px",
                    fontSize: "13px",
                    fontStyle: "italic",
                  }}
                >
                  Details verified by <b>{cusSatus.admin_name}</b>
                </p>
              ) : (
                <>
                  <p style={{ marginLeft: "5px", marginTop: "6px" }}>
                    Verify details:
                  </p>
                  <Switch
                    style={{ color: "#002d5b" }}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Button
                    sx={{ ml: 4 }}
                    className="primary-btn"
                    variant="contained"
                    onClick={sendEmail}
                  >
                    Send mail
                  </Button>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </AccordionDetails>
  );
}
export default CustOccupation;
