import { useEffect, useState } from "react";

import {
  Link,
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Swal from "sweetalert2";
import { getLocalStorage } from "../Common/cookie";
import { validatePass, validateCPass } from "../Common/validations";
import { getAdmin } from "../api/admin";
import { adminChangePassword } from "../api/adminAuth";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorCNewPassword, setErrorCNewPassword] = useState("");

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  function validateOldPassword(str) {
    setErrorOldPassword(validatePass(str).error);
  }
  function validateNewPassword(str) {
    setErrorNewPassword(validatePass(str).error);
  }
  function validateCNewPassword(str, pass) {
    setErrorCNewPassword(validateCPass(str, pass).error);
  }
  function validateForm() {
    validateOldPassword(adminData.old_password);
    validateNewPassword(adminData.new_password);
    validateCNewPassword(adminData.new_cpassword, adminData.new_password);

    let valid =
      validatePass(adminData.old_password).valid &&
      validatePass(adminData.new_password).valid &&
      validateCPass(adminData.new_cpassword, adminData.new_password).valid;
    return valid;
  }

  function onChangeData(e) {
    setAdminData((adminData) => ({
      ...adminData,
      [e.target.name]: e.target.value,
    }));
  }

  const changePass = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const response = await adminChangePassword({
        token:
          adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
        data: {
          old_password: adminData.old_password,
          new_password: adminData.new_password,
        },
      });
      if (response?.status === 200) {
        Swal.fire("Good job!", response.message, "success");
        setAdminData({
          old_password: "",
          new_cpassword: "",
          new_password: "",
        });
      } else {
        setErrorOldPassword("Old password is incorrect");
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="d-flex justify-content-end">
          <Link
            className="text-decoration-none"
            to="/subscriber?edit=1"
            style={{ visibility: "hidden" }}
          >
            <Button className="primary-btn" variant="contained">
              {" "}
              Send Mail
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box component={Paper} sx={{ overflow: "auto", p: 2 }}>
            <Table>
              <TableRow>
                <TableCell style={{ width: "20%" }}>Old Password</TableCell>
                <TableCell style={{ width: "80%" }}>
                  <TextField
                    type="password"
                    name="old_password"
                    value={adminData.old_password}
                    onChange={onChangeData}
                    onBlur={() => validateOldPassword(adminData.old_password)}
                  />
                  {errorOldPassword && (
                    <Typography className="error-text">
                      {errorOldPassword}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "20%" }}>New Password</TableCell>
                <TableCell style={{ width: "80%" }}>
                  <TextField
                    type="password"
                    name="new_password"
                    value={adminData.new_password}
                    onChange={onChangeData}
                    onBlur={() => validateNewPassword(adminData.new_password)}
                  />
                  {errorNewPassword && (
                    <Typography className="error-text">
                      {errorNewPassword}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "20%" }}>Confirm Password</TableCell>
                <TableCell style={{ width: "80%" }}>
                  <TextField
                    type="password"
                    name="new_cpassword"
                    value={adminData.new_cpassword}
                    onChange={onChangeData}
                    onBlur={() =>
                      validateCNewPassword(
                        adminData.new_cpassword,
                        adminData.new_password
                      )
                    }
                  />
                  {errorCNewPassword && (
                    <Typography className="error-text">
                      {errorCNewPassword}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </Table>
          </Box>
        </Grid>
        <Box component="form" sx={{ ml: 3 }}>
          <Button
            sx={{ mt: 3 }}
            onClick={changePass}
            variant="contained"
            className="primary-btn me-3"
          >
            {" "}
            Update Password{" "}
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default ChangePassword;
