import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider, FormControl, TextField, Button, CardContent, Box, Typography } from '@mui/material';

import logo from '../Assets/Image/logoLight.png'
import loginImg from '../Assets/Image/login.png'
import { validateUname, validatePass } from '../Common/validations'
import { adminLogin } from '../api/adminAuth'
import { removeLocalStorage, setLocalStorage } from '../Common/cookie'
import { getAdmin } from "../api/admin";

function Login() {
    const navigate = useNavigate();
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})

    useEffect(() => {
        removeLocalStorage('rv-admin')
    }, [])

    function validateUsername(str) {
        setErrors((errors) => ({
            ...errors,
            email: validateUname(str).error,
        }));
    }
    function validatePassword(str) {
        setErrors((errors) => ({
            ...errors,
            password: validatePass(str).error,
        }));
    }
    function validateForm() {
        validateUsername(data.username)
        validatePassword(data.password)

        let valid =
            validateUname(data.username).valid &&
            validatePass(data.password).valid;
        return valid;
    }

    function onChangeData(e) {
        setData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }

    const loginUser = async (e) => {
        e.preventDefault();
        removeLocalStorage('rv-admin')
        if (validateForm()) {
            const response = await adminLogin({
                data: {
                    username: data.username,
                    password: data.password
                },
            });
            if (response?.status === 200) {
                setLocalStorage('rv-admin', {
                    id: response.data.id,
                    accessToken: response.data.accessToken
                })
                // const res = await getAdmin({
                //     admin_id: response.data.id,
                //     token: adminData?.accessToken || getLocalStorage('rv-admin').accessToken
                // })
                navigate("/")
            } else {
                setErrors((errors) => ({
                    ...errors,
                    password: response.message,
                }));
            }
        }
    }


    return (
        <div className='row m-0' style={{ minHeight: "100vh" }}>
            <div className='col-md-6 col-12 d-none d-md-block primary-blue-bg'>
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <img src={loginImg} alt="" style={{ width: '75%', maxWidth: '600px' }} />
                </div>
            </div>
            <div className='col-md-6 col-12'>
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <CardContent>
                        <Box className='d-flex justify-content-between'
                            sx={{
                                p: 1,
                                bgcolor: 'backgFround.paper',
                                borderRadius: 1,
                            }}
                        >
                            <Typography>
                                <div className='d-block'><img src={logo} alt="" width="75" /></div>
                            </Typography>
                            <Typography className='d-flex align-items-end primary-text' sx={{ fontSize: "30px" }}>Login</Typography>
                        </Box>
                        <Divider className='primary-bg' />
                        <Box component='form' onSubmit={loginUser}>
                            <form>
                                <FormControl sx={{ mt: 3 }} fullWidth>
                                    <TextField
                                        label="Username"
                                        name="username"
                                        value={data.username}
                                        onChange={onChangeData}
                                        onBlur={() => validateUsername(data.username)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    {errors.username && (
                                        <Typography className='error-text'>{errors.username}</Typography>
                                    )}
                                </FormControl>
                                <FormControl sx={{ mt: 3 }} fullWidth>
                                    <TextField
                                        label="Password"
                                        type="password"
                                        name="password"
                                        value={data.password}
                                        onChange={onChangeData}
                                        onBlur={() => validatePassword(data.password)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    {errors.password && (
                                        <Typography className='error-text'>{errors.password}</Typography>
                                    )}
                                </FormControl>
                                <button onClick={loginUser} className='primary-btn login-btn'>login</button>
                                {/* <Button onClick={loginUser} sx={{ mt: 3 }} fullWidth variant="contained" className='primary-btn'> Login</Button> */}
                            </form>
                        </Box>
                    </CardContent>
                </div>
            </div>
        </div>
    );
}
export default Login;
