import axios from "axios";

//bank-personal-loans
export const bankPersonalBankPersonalLoans = (obj) => {
    return axios
        .get("/bank-personal-loan", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//get loan
export const getBankPersonalLoan = (obj) => {
    return axios
        .get("/bank-personal-loan/" + obj.loan_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//read loan
export const readBankPersonalLoan = (obj) => {
    return axios
        .patch("/bank-personal-loan/read", obj.data, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//add loan data
export const addBankPersonalLoanData = (obj) => {
    return axios
        .post("/bank-personal-loan/apply", obj.data, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//update loan data
export const updateBankPersonalLoanData = (obj) => {
    return axios
        .patch("/bank-personal-loan", obj.data, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};