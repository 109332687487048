import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Button,
  Grid,
} from "@mui/material";
import { getLocalStorage } from "../Common/cookie";
import { getAdmin } from "../api/admin";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Swal from "sweetalert2";
import BankPersonalLoanData from "../Components/bankPersonalLoanData";

function BankPersonalLoans(props) {
  const { socket } = props;
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [hideDelete, setHideDelete] = useState(true);
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  const handleExportRows = () => {
    if (downloadData.length > 0) {
      const doc = new jsPDF();
      const tableData = downloadData.map((row) => Object.values(row));
      const tableHeaders = Object.keys(downloadData[0]);

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });

      doc.save("bank_personal_loans.pdf");
    } else {
      Swal.fire({
        title: "Caution",
        text: "You can not download 0 records.",
        icon: "warning",
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="d-flex justify-content-between">
        <Grid>
            <CSVLink
              data={downloadData}
              filename={"bank_personal_loans.csv"}
              className="btn secondary-btn mr-1"
              target="_blank"
            >
              <DownloadIcon /> CSV
            </CSVLink>
            <Button
              onClick={handleExportRows}
              className="btn secondary-btn"
              variant="outlined"
            >
              <DownloadIcon /> PDF
            </Button>
          </Grid>
          <Link className="text-decoration-none ml-3" to="/bank-personal-loan?edit=1">
            <Button className="primary-btn" variant="contained">
              Add Loan
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          {/* {data.length > 0 ? */}
          <BankPersonalLoanData
            socket={socket}
            adminData={adminData}
            customerId={null}
            setDownloadData={setDownloadData}
          />
          {/* :
                            <Box sx={{ p: 3 }}><h5>You have not added any admin.</h5></Box>
                        } */}
        </Grid>
      </Grid>
    </>
  );
}

export default BankPersonalLoans;
